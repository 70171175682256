.more-work-section {
    padding: 60px 0;
    margin: 0 29.5px;
    width: initial;
    margin-top: 20px;
    
    @media screen and (max-width: 320px) {
        margin: 0 22px;
    }
    .work-items {
        grid-gap: 60px;

        @media screen and (max-width: 428px) {
            grid-gap: 49px;
        }
        .work-item {
            width: 100%;
            .work-image {
                max-width: 421px;
                max-height: 249px;
                height: 249px;
                width: initial;
                margin-bottom: 8px;

                @media screen and (max-width: 428px) {
                    height: 210px;
                }
                @media screen and (max-width: 375px) {
                    height: 185px;
                }
                @media screen and (max-width: 320px) {
                    height: 160px;
                }
            }
            .work-text {
                color: #000;
                text-transform: uppercase;
            
                h3 {
                    margin-bottom: 4px;
                    @include RubrikNewRg();
                }
            }
        }
    }
}


@import url('https://use.typekit.net/uwl0jpr.css');

@font-face {
    font-family: 'OpenSans-Light';
    src: url('../../assets/fonts/OpenSans-Light.eot');
    src: url('../../assets/fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/OpenSans-Light.woff2') format('woff2'),
        url('../../assets/fonts/OpenSans-Light.woff') format('woff'),
        url('../../assets/fonts/OpenSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-Regular';
    src: url('../../assets/fonts/OpenSans-Regular.eot');
    src: url('../../assets/fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/OpenSans-Regular.woff2') format('woff2'),
        url('../../assets/fonts/OpenSans-Regular.woff') format('woff'),
        url('../../assets/fonts/OpenSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-SemiBold';
    src: url('../../assets/fonts/OpenSans-SemiBold.eot');
    src: url('../../assets/fonts/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/OpenSans-SemiBold.woff2') format('woff2'),
        url('../../assets/fonts/OpenSans-SemiBold.woff') format('woff'),
        url('../../assets/fonts/OpenSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-Bold';
    src: url('../../assets/fonts/OpenSans-Bold.eot');
    src: url('../../assets/fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/OpenSans-Bold.woff2') format('woff2'),
        url('../../assets/fonts/OpenSans-Bold.woff') format('woff'),
        url('../../assets/fonts/OpenSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-ExtraBold';
    src: url('../../assets/fonts/OpenSans-ExtraBold.eot');
    src: url('../../assets/fonts/OpenSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/OpenSans-ExtraBold.woff2') format('woff2'),
        url('../../assets/fonts/OpenSans-ExtraBold.woff') format('woff'),
        url('../../assets/fonts/OpenSans-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@mixin font-smoothing() {
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;
}

// @include OpenSansLt();
@mixin OpenSansLt() {
    font-family: 'OpenSans-Light';
    font-style: normal;
    @include font-smoothing();
}

// @include OpenSansRg();
@mixin OpenSansRg() {
    font-family: 'OpenSans-Regular';
    font-style: normal;
    @include font-smoothing();
}

// @include OpenSansSb();
@mixin OpenSansSb() {
    font-family: 'OpenSans-SemiBold';
    font-style: normal;
    @include font-smoothing();
}

// @include OpenSansBl();
@mixin OpenSansBl() {
    font-family: 'OpenSans-Bold';
    font-style: normal;
    @include font-smoothing();
}

// @include OpenSansEb();
@mixin OpenSansEb() {
    font-family: 'OpenSans-ExtraBold';
    font-style: normal;
    @include font-smoothing();
}


// @include RubrikNewLt();
@mixin RubrikNewLt() {
    font-family: 'rubrik-new', sans-serif;
    font-weight: 300;
    font-style: normal;
    @include font-smoothing();
}

// @include RubrikNewRg();
@mixin RubrikNewRg() {
    font-family: 'rubrik-new', sans-serif;
    font-weight: 400;
    font-style: normal;
    @include font-smoothing();
}
// @include RubrikNewRgIt();
@mixin RubrikNewRgIt() {
    font-family: 'rubrik-new', sans-serif;
    font-weight: 400;
    font-style: italic;
    @include font-smoothing();
}

// @include RubrikNewSb();
@mixin RubrikNewSb() {
    font-family: 'rubrik-new', sans-serif;
    font-weight: 500;
    font-style: normal;
    @include font-smoothing();
}

// @include RubrikNewBd();
@mixin RubrikNewBd() {
    font-family: 'rubrik-new', sans-serif;
    font-weight: 700;
    font-style: normal;
    @include font-smoothing();
}
// @include RubrikNewBd();
@mixin RubrikNewSbIt() {
    font-family: 'rubrik-new', sans-serif;
    font-weight: 700;
    font-style: italic;
    @include font-smoothing();
}



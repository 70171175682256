.careers {
    padding: 160px 45px 75px;
}

.careers-filters {
    iframe {
        width: 90%;
        height: 650px;
    }
}

.careers-more {
    max-width: 80%;

    h6 {
        font-size: 32px;
    }
}
.loader {
    background-color: #d40101;
    color: #141414;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    text-transform: uppercase;
    width: 100vw;
    height: 100vh;
    z-index: 1090;
}

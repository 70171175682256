.our-work {

    &_view {
        padding-top: 80px;
    }
    
    &_grid {
        .btn-route {
            margin-top: 96px;
        }
    }
    
    .work-tips {
        position: relative;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 10px;
    
        margin-bottom: 90px;
        
        &_wrapper {
            cursor: pointer;
            padding: 8px 22.5px;
            border-radius: 22.5px;
            border: solid 1px #cdcdcd;
            transition: all .4s ease;
            &[data-active="true"] {
                background: #d40101;
                color: #fff;
            }
            &[data-active="false"] {
                background: transparent;
                color: #000;
            }

            @media (hover: hover) {
                &:hover {
                    transition: all .4s ease;
                    background-color: #d40101;
                    color: #ffffff;
                }
            }
        }
        &_tip {
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 500;
            line-height: 1.38;
        }
    }

    .work-result {
        position: relative;
        
        .no-matches {
            position: absolute;
        }
    }


    .grid-list {
        grid-template-columns: repeat(auto-fill, minmax(416px, 1fr));
        gap: 50px 30px;

        &_item {
            a {
                &:hover {
                    .grid-image {
                        img {
                            filter: grayscale(0);
                            -webkit-filter: grayscale(0);
                            transition: all 0.8s;
                        }
                    }
                }
            }
        }

        .grid-image {
            overflow: hidden;

            &:hover {
            
                img {
                    filter: grayscale(0);
                    -webkit-filter: grayscale(0);
                    transform: scale(1.19);
                    transition: all 0.8s;
                }
            }

            img {
                height: 246px;
                transform: scale(1);
                transition: all 0.5s;
                filter: grayscale(1);
                -webkit-filter: grayscale(1);
            }
        }

        
        .grid-text {
            margin-top: 30px;

            h3 {
                @include RubrikNewLt();
                font-size: 18px;
                color: #000;
                text-transform: uppercase;
                line-height: 1.5;
            }
            p {
                @include RubrikNewSb();
                font-size: 28px;
                color: #000;
                text-transform: uppercase;
            }
        }
    }

}

.accordion {
    display: flex;
    flex-direction: column;
    margin-top: 39px;

    &_head {
        position: relative;
        display: flex;
    }

    &_title {
        display: flex;
        align-items: center;
        @include OpenSansRg();
        font-size: 21px;
        color: #000;

        svg {
            //margin-top: 5px;
            margin-left: 10px;
            vertical-align: middle;
        }
    }

    &_btn-close {
        position: absolute;
        right: 0;
        top: -2px;
        opacity: 0;
        transition: opacity 0.45s;

        &.fadeIn {
            opacity: 1;
            transition: opacity 0.45s;
        }
    }
    
    .rotate-180 {
        margin-top: 5px;
        margin-left: 10px;
        transform: rotate(180deg);
    }
}

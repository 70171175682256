.news {
    padding: 65px 22px 73px;

    &-tiles {
        flex-wrap: wrap;
        justify-content: center;

        .tile {
            width: 511px;
            margin-top: 35px;
            margin-left: 0;
            margin-bottom: 32px;

            &:first-child {
                margin-top: 0;
            }
        }

        img {
            max-width: 100%;
        }
    }
    .arrows {
        display: none;
    }
    .dots {
        display: none;
    }
}


@media screen and (max-width: 480px) {
    .news {
         .keen-slider__slide{ 
            h3 {
                display: none;
            }
        }
        .btn-route {
            margin-top: 51.5px;
            font-size: clamp(14px, 5vw, 21px);
            font-weight: 500;
            border-width: 2px;
            padding: 24.5px 52px;
        }
    }
}
.new-news {

    &_view {
        padding-top: 80px;
    }
    &_grid {
        .btn-route {
            margin-top: 96px;
        }
    }

    
    .grid-list {
        grid-template-columns: repeat(auto-fill, minmax(411px, 1fr));
        gap: 60px 38px;


        .grid-image {
            img {
                height: 411px;
            }
        }

        .grid-text {
            margin-top: 30px;

            h3 {
                @include OpenSansSb();
                font-size: 24px;
                color: #000;
                line-height: 1.2;
            }
        }
    }
}

 
 .new-news-tips {
     position: relative;
     flex-direction: row;
     justify-content: flex-start;
     flex-wrap: wrap;
     gap: 10px;

    //  margin-top: 45px;
     margin-bottom: 83px;
    
     &_wrapper {
         cursor: pointer;
         padding: 8px 22.5px;
         border-radius: 22.5px;
         border: solid 1px #cdcdcd;
         transition: all .4s ease;
         &[data-active="true"] {
             background: #d40101;
             color: #fff;
         }
         &[data-active="false"] {
             background: transparent;
             color: #000;
         }
    
         @media (hover: hover) {
             &:hover {
                 transition: all .4s ease;
                 background-color: #d40101;
                 color: #ffffff;
             }
         }
     }
     &_tip {
         text-transform: uppercase;
         font-size: 13px;
         font-weight: 500;
         line-height: 1.38;
     }

 }

 .new-news-result {
     position: relative;
    .no-matches {
        position: absolute;
    }
 }

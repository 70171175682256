.loader-wrapper {
    display: grid;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1300;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    
    overflow-y: scroll;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1250;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    box-sizing: border-box;
    @include RubrikNewSb();
    
    .team-detail {
        width: 100%;
        height: 100%;
        .keen-slider {
            height: 100%;
            width: 100%;
            .keen-slider__slide {
                cursor: default;
                padding-top: 0;
                padding-left: 0;
                padding-right: 0;
                .animate-wrapper {
                    display: flex;
                    position: relative;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .modal-nav {
            position: absolute;
            right: 50px;
            top: 60px;
            .arrows {
                opacity: 1;
                .arrow-wrapper-left {
                    right: 140px;
                    left: inherit;
                    &:hover {
                        svg g {
                            fill: #db0400;
                        }
                    }
                }

                .arrow-wrapper-right {
                    right: 90px;
                    left: inherit;
                    margin-left: 13px;
                    &:hover {
                        svg g {
                            fill: #db0400;
                        }
                    }
                }

                .arrow-wrapper {
                    cursor: pointer;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;

                    width: inherit;
                    height: inherit;
                    transform: none;
                    background-color: transparent;
                    .arrow--left {
                        height: 18px;
                        transform: rotate(180deg);
                    }
                    .arrow--right {
                        height: 18px;
                        transform: rotate(0);
                    }
                }
            }
        }

        &_back-arrow {
            display: flex;
            align-items: center;
            position: absolute;
            top: 45px;
            left: 50px;
            z-index: 1090;
            text-decoration: none;
            border-radius: 24px;
            padding: 7px 25px 7px 18px;
            color: #000;
            background-color: transparent;
            transition: all .5s ease;

            &:hover {
                color: #fff;
                background-color: rgba(0, 0, 0, 0.7);
                transition: all .8s ease;
            }

            svg {
                transform: rotate(90deg);
            }
            p {
                @include RubrikNewRg();
                font-size: 16px;
                margin-left: 20px;
                text-transform: uppercase;
            }
        }
        &_about {
            display: flex;
            width: 100%;
            
            .img-block {
                flex: 1.18;
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                &_wrapper {
                    position: relative;
                    max-height: 950px;
                    width: 100%;
                    img {
                        max-width: 100%;
                        display: block;
                        width: 100%;
                        max-height: 100%;
                    }
                    
                    .scroll-btn {
                        display: none;
                    }
                }
                
            }
            .description-block {
                flex: 1;
                color: #fff;
                position: relative;
                padding: 60px 65px 20px 65px;
                background-color: #141414;

                overflow-y: scroll;
                @media screen and (min-width: 1980px) {
                    flex: 2 1;
                }
                .slider-size {
                    display: inline-block;
                    @include OpenSansRg();
                    font-size: 14px;
                    color: #cdcdcd;
                }
                &_position {
                    color: #fff;
                    @include OpenSansRg();
                    font-size: 14px;
                    line-height: 2.14;
                    text-transform: uppercase;
                    margin-top: 40px;
                }
                &_title {
                    color: #fff;
                    @include RubrikNewRg();
                    font-size: 52px;
                    line-height: 1.08;
                    text-transform: uppercase;
                    
                    margin-bottom: 25px;
                }
                
                .read-wrapper {
                    margin-bottom: 25px;
                    cursor: pointer;
                    //.more-text {
                    //    display: flex;
                    //    flex-direction: column;
                    //    p {
                    //        @include OpenSansRg();
                    //        font-size: 16px;
                    //        line-height: 1.5;
                    //        letter-spacing: normal;
                    //        color: #c4c4c4;
                    //        margin-bottom: 10px;
                    //    }
                    //    span {
                    //        @include OpenSansRg();
                    //        font-size: 16px;
                    //        color: #db0400;
                    //    }
                    //}
                }
                
                &_links {
                    margin-bottom: 40px;
                    &-list {
                        align-items: flex-start;
                        li {
                            a {
                                color: #fff;
                                font-size: 16px;
                                line-height: 1.88;
                                text-decoration: none;
                                
                                transition: all .4s ease;
                                &:hover {
                                    text-decoration: none;
                                    color: #d40101;
                                    transition: all .4s ease;
                                }
                            }
                        }
                    }
                    h2 {
                        margin-bottom: 15px;
        
                        text-transform: uppercase;
                        font-size: 18px;
                        line-height: 1.33;
                        color: #fff;

                        &::after {
                            content: "";
                            margin-top: 15px;
                            display: block;
                            height: 1px;
                            width: 100%;
                            background-color: #505050;
                        }
                    }
                }
                
                &_works {
                    h2 {
                        margin-bottom: 15px;
                        
                        text-transform: uppercase;
                        font-size: 18px;
                        line-height: 1.33;
                        color: #fff;

                        &::after {
                            content: "";
                            margin-top: 15px;
                            display: block;
                            height: 1px;
                            width: 100%;
                            background-color: #505050;
                        }
                    }
                    .works-item {
                        display: flex;
                        align-items: center;
                        padding: 10px 0;
                        border-top: 1px solid #232323;
                        border-bottom: 1px solid #232323;
                        margin-left: 0;
                        margin-bottom: 0;
                        &_img-block {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            
                            width: 95px;
                            height: 90px;
                            min-width: 95px;

                            padding: 10px;
                            margin-right: 20px;
                            background-color: #232323;
                            img {
                                max-width: 85px;
                                max-height: 85px;
                                background-color: transparent;
                            }

                        }
                        &_title {
                            @include OpenSansRg();
                            font-size: 14px;
                            color: #cdcdcd;
                            line-height: 1.5;
                        }
                    }
                }
            }
        }
    }
}

// global vars

// colors
$purple: #6b6d93;
$darkPurple: #262534;
$brightPurple: #d04efd;
$textPurple: #70798d;
$titlePurple: #7162a9;
$red: #d40101;
$gray: #cfcfcf;
$deepdark: #000000;


// breakpoints
$desktop: 1600;
$tablet: 1080;
$mobile: 767;

$desktop-bp: $desktop+'px';
$tablet-bp: $tablet+'px';
$mobile-bp: $mobile+'px';

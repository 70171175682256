.not-found {
    position: relative;
    height: 100vh;
    width: 100%;
    background-repeat: no-repeat;

    &_container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    &_head {
        @include RubrikNewRg();
        font-size: 165px;
        text-align: center;
        color: #fff;
    }

    &_body {
        margin-top: 15px;
        @include RubrikNewSb();
        font-size: 24px;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        line-height: 1.5;
    }
}

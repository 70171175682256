.more-news-section {
    position: relative;
    padding: 85px 0;
    margin: 0 66px;
    border-top: 1px solid #ccc;
    width: 100%;
    h1 {
        margin-bottom: 40px;
        display: inline-block;
        position: relative;
        text-transform: uppercase;
        font-size: 21px;
        font-weight: 500;
        &:after {
            content: "";
            display: block;
            margin-top: 2px;
            height: 2px;
            width: 47px;
            background-color: #d40101;
        }
    }
    .news-items {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        grid-gap: 30px;
        width: 100%;
        a {
            display: block;
            text-decoration: none;
        }
        .news-item {
            max-width: 410px;
            width: 410px;
            .news-image {
                margin-bottom: 33px;
                max-width: 410px;
                width: 410px;
                background-position: center;
                background-size: cover;
                max-height: 400px;
                height: 400px;
            }
            .news-text {
                h3 {
                    font-weight: 600;
                    font-size: 18px;
                    margin-bottom: 7px;
                    color: #000;
                }
            }
        }
    
    }
}

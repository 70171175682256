.news {
    position: relative;
    padding: 80px 65px;
    text-align: center;
    &:hover {
        .arrows {
            transition: all .4s ease;
            opacity: 1;
        }
    }
    &-title {
        font-size: 21px;
        margin-bottom: 30px;
        @include RubrikNewSb();
        color: $deepdark;
        text-transform: uppercase;
        text-align: left;

        &::after {
            content: "";
            display: block;
            margin-top: 3px;
            height: 2px;
            width: 47px;
            background-color: $red;
        }
    }

    .keen-slider {
        &__slide {
            width: 100%;
            a {
                text-decoration: none;
                .news-item {
                    position: relative;
                    overflow: hidden;
                    max-width: 411px;

                }
            }
            img {
                margin-bottom: 20px;
                max-width: 100%;
                // transform: scale(1);
                // transition: all 1s;
            }
            &:hover img {
                // transform: scale(1.1);
            }
            h3 {
                text-align: left;
                @include RubrikNewSb();
                font-size: 24px;
                font-weight: 500;
                color: #000;
                margin-right: 15px;
            }
        }
       
        
    }
    .arrows {
        opacity: 0;
        .arrow-wrapper-left {
            left: 100px;
        }
        
        .arrow-wrapper-right {
            right: 100px;
        }
        
        .arrow-wrapper {
            cursor: pointer;
            width: 70px;
            height: 70px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            
            border-radius: 50%;
            background-color: #d40101;
            
            position: absolute;
            top: 45%;
            transform: translateY(-40%);
            .arrow--left {
                width: 20px;
                transform: rotate(180deg);
            }
            .arrow--right {
                width: 20px;
                transform: rotate(0deg);
            }
        }
    }
    
    .dots {
        display: flex;
        gap: 15px;
        justify-content: center;
        align-items: center;
        margin-top: 55px;
        margin-bottom: 60px;
        .dot {
            cursor: pointer;
            padding: 4px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: transparent;
            border: solid 1px #000000;
        }
        .active {
            padding: 4px;
            border: solid 1px #d40101;
            background-color: #d40101;
        }
    }
}

// Parallax Hover Styles
.ph-wrapper {
  position: relative;
  transition: all 0.3s ease-out;
  margin: 0;

  .ph-shadow,
  .ph-layers,
  .ph-layer,
  .ph-lighting {
    transition: all 0.3s ease-out;
  }
  
  .ph-shadow,
  .ph-layers,
  .ph-layer,
  .ph-lighting {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  
  img {
    border-radius: 4px;
  }

  .ph-text > * {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 4rem;
    color: #fff;
    font-weight: 300;
  }

  .ph-shadow {
    height: 90%;
    width: 90%;
    left: 5%;
    top: 5%;
    background: none;
  }

  .ph-lighting {
    background-image: linear-gradient(135deg,
                      rgba(255, 255, 255, 0) 0%,
                      rgba(255, 255, 255, 0) 33%);
  }

  .ph-heading {
    margin: 0;
    padding: 0;
    color: #fff;
    font-size: 3rem;
    text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
  }

}

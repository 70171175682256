html {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
}

body {
    @include RubrikNewRg();
    font-size: 14px;
    background-color: #1a1a1a;
}

.app-container {
  max-width: 1440px;
  margin: 0 auto;
  overflow-x: hidden;
  background-color: #fff;
}

.flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.grid {
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  justify-items: center;
}

.line-text {
  display: inline-block;
  margin-left: 30px;
  padding-bottom: 5px;
  position: relative;
  font-size: 21px;
  @include RubrikNewSb();
  text-transform: uppercase;

  &:before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    background-color: #d40101;
    width: 10px;
    height: 2px;
  }
}

.smooth-scroll {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
    .scroll {
        pointer-events: none;
    }
}

.btn-border-anim {
    position: absolute;
    border-width: 1px;
    border-style: solid;
    border-radius: 50%;
    opacity: 0;

    &_on {
        animation: 3s ease-in-out .75s infinite normal none running play-border-animation;
    }
}

@keyframes play-border-animation {
    0% {
        -webkit-transform: scale(1);
        -moz-transofrom: scale(1);
        -o-transofrom: scale(1);
        transform: scale(1);
    }
    50% {
        opacity: 0.7;
    }
    75% {
        opacity: 0.1;
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(2.5);
        -moz-transform: scale(2.5);
        -o-transform: scale(2.5);
        transform: scale(2.5);
    }
}
video {
    background-color: #fff !important;
    box-sizing: content-box;
    display: block;
    border: none;
    box-shadow: none;
    outline:none;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}


//pages
.page {
    position: relative;

    &_view {
        padding: 104px 65px;
        max-width: 1460px;
        margin: 0 auto;
    }

    .header {
        background-color: #000;
        &-nav {
            padding: 25px 65px 15px;

            ul {
                padding-top: 25px;
            }
        }
    }
    .scrolled {
        .header {
            &-nav {
                padding: 15px 65px 9px;
            }
        }
    }

    &_title {
        font-size: 52px;
        @include RubrikNewRg();
        text-transform: uppercase;
        color: #000;
    }

    &_description {
        margin-top: 16px;
        max-width: 58%;
        font-size: 16px;
        @include OpenSansRg();
        color: #000;
        line-height: 1.5;
    }
}

// style for Rich Texts
.h1 {
    font-size: 32px;
    margin: 10px 0;
}

.h2 {
    font-size: 24px;
    margin: 10px 0;
}

.h3 {
    font-size: 18px;
    margin: 10px 0;
}

.h6 {
    margin: 30px 0;
}

.blockquote {
  b {
    display: block;
    margin: 45px 5% 45px 10%;
    position: relative;
    @include RubrikNewRg();
    font-size: 24px;
    line-height: 1.33;
    color: #000;
    
    &::before {
        content: '＂';
        position: absolute;
        top: 12px;
        left: -45px;
        font-size: 96px;
        color: #000;
        line-height: 1;
        font-style: italic;
        transform: rotate(180deg);
        width: 77px;
        text-align: center;
        height: 30px;
    }
  }
}


.no-matches {
    opacity: 0;
    width: 100%;
    text-align: center;
    font-size: 18px;
    @include OpenSansRg();
}

.fade-up-spring {
    animation-fill-mode: both;
    animation-name: fadeUpBottomSpring;
}

@keyframes fadeUpBottomSpring {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }

    60% {
        opacity: 0.85;
        transform: translateY(0);
    }

    80% {
        opacity: 0.95;
        transform: translateY(4px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
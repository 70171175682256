 .columns {
    display: grid;
    grid-template-columns: repeat( 4, minmax(250px, 1fr) );
    .column {
      width: 100%;
      height: 100%;
      display: block;
      background-color: #db0400;
      
    }
  }

 .animated-logo {
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
 }
 
 

.modal {
    //height: 100%;
    .team-detail {
        overflow-y: scroll;
        .animate-wrapper {
            overflow: scroll !important;
        }
        .modal-nav {
            top: 45px;
            right: 26px;
            .arrows {
                display: block;
                padding-top: 0;
                .arrow-wrapper-left {
                    right: 60px;
                }
                .arrow-wrapper-right {
                    right: 10px;
                }
            }
        }
        .slider-size {
            top: 40px;
        }

        &_back-arrow {
            top: 30px;
            left: 15px;
            opacity: 0.4;
            background-color: #000;
            padding: 7px 20px;

            svg {
                g{
                    fill: #fff;
                }
            }
            p{
                margin-left: 25px;
                color: #fff;
                font-size: 14px;
                @include OpenSansRg();
                letter-spacing: 1px;
            }
        }
        &_about {
            .animate-wrapper {
                flex-direction: column;
            }
            .img-block {
                min-height: 865px;
                
                @media screen and (max-width: 621px){
                    min-height: 685px;
                }

                @media screen and (max-width: 481px){
                    min-height: 570px;
                }

                @media screen and (max-width: 415px) {
                    min-height: 500px;
                }

                @media screen and (max-width: 376px) {
                    min-height: 435px;
                }

                @media screen and (max-width: 321px) {
                    min-height: 415px;
                }
            }
            .img-block_wrapper {
                .scroll-btn {
                    display: flex;
                    position: absolute;
                    width: 65px;
                    height: 65px;
                    border: solid 3px #fff;
                    background-color: transparent;
                    border-radius: 50%;
                    transition: all .4s ease;
                    z-index: 2;                    
                    cursor: pointer;
                    &.position {
                        left: 50%;
                        bottom: 8%;
                        transform: translateX(-50%);
                    }
                    &:hover {
                        transition: all .4s ease;
                        background-color: #d40101;
                        border-color: #d40101;
                    }
                }
            }
            
            .description-block {
                overflow: unset;
                padding: 45px 26px 120px;
                @media screen and (max-width: 375px) {
                    padding: 45px 17px 120px;
                }

                &_line {
                    height: 26px;
                    width: 100%;
                    background-color: #141414;
                }

                &_position {
                    margin-top: 0;
                    font-size: 16px;
                    line-height: 1.92;
                }
        
                &_title {
                    font-size: 56px;
                    @media screen and (max-width: 480px) {
                        font-size: clamp(32px, 11.8vw, 56px);
                        margin-bottom: 27px;
                    }
                }
        
                .read-wrapper {
                    margin-bottom: 75px;
                    @media screen and (max-width: 480px) {
                        margin-bottom: 55px;
                    }
        
                    .more-text {
                        p {
                            line-height: 1.78;
                        }
                    }
                }

                &_links {
                    margin-bottom: 45px;
                    h2 {
                        @include RubrikNewRg();
                        font-size: 21px;
                        @media screen and (max-width: 480px) {
                            font-size: clamp(16px, 5.2vw, 21px);
                        }
                    }

                    ul {
                        a {
                            display: block;
                            @include OpenSansRg();
                            line-height: 2;
                            font-size: 21px;
                            text-decoration: none;
                            @media screen and (max-width: 480px) {
                                font-size: clamp(16px, 5.2vw, 21px);
                            }
                        }
                    }
                }
        
        
                &_works {
                    display: flex;
                    flex-direction: column;
                    gap: 30px;

                    h2 {
                        font-size: 21px;
                        @media screen and (max-width: 480px) {
                            font-size: clamp(16px, 5.2vw, 21px);
                        }
                    }
        
                    .works-item {
                        flex-direction: column;
                        width: 100%;
                        padding: 7px 0;
                        margin-right: 0;
                        letter-spacing: -0.1px;
                        border-bottom: none;
                        border-top: none;

                        &:nth-child(2) {
                            margin-top: -30px;
                        }
            
                        &_img-block {
                            max-width: 431px;
                            max-height: 443px;
                            width: 100%;
                            height: 100%;
                            padding: 20px 0;
                            margin-right: 0;
                            margin-bottom: 15px;

                            @media screen and (max-width: 480px) {
                                max-width: 100%;
                                max-height: 100%;
                                padding: 22px 10px;
                            }

                            @media screen and (max-width: 428px) {
                                padding: 8px;
                            }
                
                            img {
                                width: 100%;
                                display: block;
                                height: 200px;
                                max-width: 200px;
                                max-height: 200px;
                                object-fit: contain;

                                @media screen and (max-width: 480px) {
                                    height: 100%;
                                    max-width: 400px;
                                    max-height: 400px;
                                }
                            }
                        }
            
                        &_title {
                            font-size: 20px;
                            line-height: 1.41;
                            @media screen and (max-width: 480px) {
                                font-size: clamp(15px, 5vw, 20px);
                            }
                        }
                    }
                }
        
                .slider-size {
                    font-size: clamp(18px, 6vw,24px);
                    // @include RubrikNewRg();
                    margin-bottom: 30px;
                }
            }
        }
        
        .keen-slider{
            .keen-slider__slide {
                height: 100%;
                overflow: scroll !important;
            }
        }
    }
}
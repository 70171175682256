.not-found {
    background-size: cover;
    
    &_container {
        
        @media only screen and (max-height: 415px) {
            padding-top: 25px;
        }
    }

    @media only screen and (max-height: 415px) {
        &_head {
            font-size: 112px;
        }

        &_body {
            font-size: 18px;
        }
    }

    @media screen and (max-width: 480px) {
        
        &_head {
            font-size: 142px;
        }

        &_body {
            font-size: 18px;
        }
    }
}


.news-detail {

    &_view {
        padding: 132px 0px 65px;
    }

    &_head {
        padding: 0 24px;
    }

    &_body {
        padding: 0;

        p, h1, h2, h6 {
            padding-left: 24px;
            padding-right: 24px;
        }
    }

    &_title {
        padding: 0;
        margin-top: 17.5px;
        font-size: 36px;
        line-height: 1.1;
    }
    &_image {
        margin-top: 34.5px;
        padding: 0;
    }

    .news {
        padding-bottom: 70px;
    }

    .news-title {
        margin-top: 70.5px;
        &:after {
            display: none;
        }
    }
}


.article {

    &_share-box {
        margin-top: 40px;
    }
    
    &_text {
        padding-left: 0;
        padding-right: 0;

        h6 {
            margin-top: 30px;
            font-size: 24px;
        }

        p {
            font-size: 18px;
            line-height: 1.78;

            a {
                font-size: inherit;
            }
        }
        .blockquote {
          b {
              display: block;
              margin: 30px 0 30px 12%;
              font-size: 24px;
              line-height: 1.33;
              font-weight: 400;

              &::before {
                top: 13px;
                left: -55px;
                font-size: 96px;
                font-weight: 700;
              }
          }
        }
    }

    &_social {
        display: none;
    }
}


.article-media {
    &_wrap {
        margin: 47px 0;

        .play-btn {
            width: 35px;
            height: 35px;

            .btn-border-anim {
                width: 40px !important;
                height: 40px !important;
            }

            svg {
                width: 22px;
                height: 20px;
            }
        }
    }
}

@media screen and (max-width: 428px) {
    .news-detail {

        &_head {
            padding: 0 15px;
        }

        &_body {
            padding: 0;

            p, h1, h2, h3, h4, h5, h6 {
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        &_title {
            font-size: 24px;
        }
    }

    
    .article {

        &_text {
             h6 {
                font-size: 21px;
            }

            p {
                font-size: 16px;
                line-height: 1.45;

                a {
                    font-size: inherit;
                }
            }
            .blockquote {
            b {
                display: block;
                margin: 25px 0 25px 14%;
                font-size: 18px;
                line-height: 1.13;

                &::before {
                    top: -2px;
                    left: -60px;
                    font-size: 70px;
                }
            }
            }
        }
    }

    
    .article-media {
        &_wrap {
            margin: 35px 0;
        }
    }
}
.team {
    &-about {
        padding: 0 23.5px 60px;

        &::before {
            margin-bottom: 52px;
        }
        .numbers {
            margin-top: 40px;
            flex-wrap: wrap;

            .percent {
                @include RubrikNewRg();
                font-size: 42px;
            }
            p {
                @include RubrikNewRg();
                font-size: 16px;
            }
            > div {
                flex: 0 0 50%;
                margin-bottom: 40px;
            }
            li {
                margin-left: 15px;

                @media screen and (max-width: 480px) {
                    margin-left: 0;
                }

                @media screen and (max-width: 390px) {
                    width: 83px;
                }

                &:first-child {
                    margin-left: 0;
                    
                    @media screen and (max-width: 390px) {
                        margin-right: 5px;
                    }
                }
            }

        }

         .line-text {
            margin-right: 0;

        }
    }
    &-items {
        margin-bottom: 133px;
        //padding-left: 20px;
        .keen-slider {
            &__slide {
                &-wrapper {
                    width: 290px;
                    min-height: 360px;
                    max-height: 360px;
                }
                &-img {
                    max-width: 450px;
                    min-height: 360px;
                    max-height: 360px;
                }
                .hidden-item {
                    max-height: 420px;
                    &__position {
                        line-height: 1.5;
                    }
                    &__img-preview {
                        max-width: 85px;
                        max-height: 85px;
                        &:nth-child(1) {
                            right: -45px;
                        }
                        &:nth-child(2) {
                            left: -66px;
                            bottom: 180px;
                        }
                        &:nth-child(3) {
                            max-width: 130px;
                            max-height: 130px;
                            right: -25px;
                            bottom: -38px;
                            img {
                                max-height: 130px;
                                height: 130px;
                            }
                        }
                        img {
                            height: 85px;
                            max-width: 85px;
                            max-height: 85px;
                        }
                    }
                }
                
            }
        }
        .arrows {
            display: flex;
            justify-content: center;
            opacity: 1;
            display: none;
            .arrow-wrapper {
                display: none;
                top: auto;
                bottom: -40px;
                background-color: unset;
                width: auto;
                height: auto;

                &-right {
                    bottom: -39px;
                    right: 50%;
                    transform: translateX(70px);
                }
                &-left {
                    left: 50%;
                    transform: translateX(-70px);
                }

                .arrow--left,
                .arrow--right {
                    width: 58px;
                    height: 28px;
                }

                .arrow-desk {
                    display: none;
                }

                .arrow-mob {
                    display: block;
                }
            }
        }
    }
}



@media screen and (max-width: 480px) {
    .team {
         &-items {
            margin-bottom: 133px;
            .keen-slider {
                &__slide {
                    .show-bg:hover {
                        .hidden-item {
                            &__img-preview {
                                &:nth-child(n) {
                                    transform: none;
                                }
                            }
                        }
                    
                    }

                    &-wrapper {
                        width: 330px;
                        min-height: 418px;
                        max-height: 418px;
                        .hidden-item {
                            opacity: 1;
                            &__img-preview {
                                &:nth-child(1) {
                                    top: -55px;
                                    right: 23px;
                                }
                                &:nth-child(2) {
                                    left: -50px;
                                }
                                &:nth-child(3) {
                                    max-width: 160px;
                                    max-height: 133px;
                                    right: -48px;
                                    bottom: -40px;
                                    img {
                                        max-height: 160px;
                                        height: 160px;
                                    }
                                }
                            }
                        }
                    }
                    &-img {
                        min-height: 418px;
                        max-height: 418px;
                        background-color: #db0400;
                    }
                    &-name {
                        display: none;
                    }
                }
            }
        }
        .btn-route {
            margin-top: 96px;
            font-size: clamp(14px, 5vw, 21px);
            font-weight: 500;
            border-width: 2px;
            max-width: 298px;
            width: 100%;
            padding: 22px 0;
        }
    }
}

@media screen and (max-width: 414px) {
    .team {
         &-items {
            .keen-slider {
                &__slide {
                    &-wrapper {
                        width: 305px;
                        min-height: 412px;
                        max-height: 412px;
                        .hidden-item {
                            &__position {
                                max-width: 210px;
                            }
                            &__name {
                                font-size: 32px;
                                max-width: 210px;
                            }
                            &__img-preview {
                                &:nth-child(3) {
                                    max-width: 130px;
                                    max-height: 130px;
                                    bottom: -45px;
                                
                                    img {
                                        max-height: 130px;
                                        height: 130px;
                                    }
                                }
                            }
                        }
                    }
                    &-img {
                        min-height: 412px;
                        max-height: 412px;
                    }
                }
            }
        }
        .btn-route {
            max-width: 268px;
        }
    }
}

@media screen and (max-width: 390px) {
    .team {
         &-items {
            .keen-slider {
                &__slide {
                    &-wrapper {
                        width: 285px;
                        min-height: 380px;
                        max-height: 380px;
                    }
                    &-img {
                        min-height: 380px;
                        max-height: 380px;
                    }
                }
            }
        }
        .btn-route {
            max-width: 220px;
        }
    }
}

@media screen and (max-width: 375px) {
    .team {
         &-items {
            .keen-slider {
                &__slide {
                    &-wrapper {
                        width: 275px;
                        min-height: 375px;
                        max-height: 375px;
                        .hidden-item {
                            &__img-preview {
                                &:nth-child(3) {
                                    max-width: 115px;
                                    max-height: 115px;
                                    bottom: -35px;
                                    right: -40px;
                            
                                    img {
                                        max-height: 115px;
                                        height: 115px;
                                    }
                                }
                            }
                        }
                    }
                    &-img {
                        min-height: 375px;
                        max-height: 375px;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 320px) {
    .team {
         &-items {
            .keen-slider {
                &__slide {
                    &-wrapper {
                        width: 225px;
                        min-height: 300px;
                        max-height: 300px;
                    
                        .hidden-item {
                            &__position {
                                max-width: 190px;
                                padding-left: 20px;
                                font-size: 11px;
                            }
                    
                            &__name {
                                max-width: 190px;
                                font-size: 28px;
                                padding: 0 0 20px 20px;
                            }
                    
                            &__img-preview {
                                max-width: 60px;
                                max-height: 60px;
                                &:nth-child(1) {
                                    top: -38px;
                                }
                    
                                &:nth-child(2) {
                                    left: -47px;
                                    bottom: 150px;
                                }
                    
                                &:nth-child(3) {
                                    max-width: 95px;
                                    max-height: 95px;
                                    bottom: -35px;
                    
                                    img {
                                        max-height: 95px;
                                        height: 95px;
                                    }
                                }

                                img {
                                    max-height: 60px;
                                    height: 60px;
                                }
                            }
                        }
                    }
                    
                    &-img {
                        min-height: 300px;
                        max-height: 300px;
                    }
                }
            }
        }
        .btn-route {
            max-width: 208px;
        }
    }
}
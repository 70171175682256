.works {
    position: relative;
    max-width: 1640px;
    margin: 0 auto;
    padding-bottom: 100px;
    text-align: center;
    
    .line-text {
        position: absolute;
        left: 0;

        &:before {
            width: 108px;
        }
    }

    &-items {
        display: flex;
        flex-direction: column;
        //gap: 90px;
        text-align: left;
    }
    &-item {
        max-width: 1040px;
        align-self: flex-end;
        margin-bottom: 90px;

        &:last-child {
            margin-bottom: 0;
        }
        
        &:nth-child(2n) {
            align-self: flex-start;
        }
        &:nth-of-type(2n) {
            .works-about {
                margin-left: 65px;
            }
        }
        a {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: relative;
            img {
                display: block;
                max-width: 1024px;
                width: 100%;
                height: 100%;
            }
            .hidden-icon {
                display: flex;

                width: 70px;
                height: 70px;
                position: absolute;
                margin-right: 10em;
                border-radius: 50%;
                border: 2px #ffffff solid;
            }
        }
        .frame {
            position: relative;
            z-index: 0;
            overflow: hidden;
            .bg-image {
                position: relative;
                transform: scale(1);
                z-index: 1;
                opacity: 1;
                transition: all 0.3s;
                filter: grayscale(1);
                -webkit-filter: grayscale(1);
                    /*opacity: 0; was used with extra hover image */
            }
            // .bg-image-hover {
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     z-index: 0;
            // }

            .logo {
                position: absolute;
                width: 265px;
                height: 50px;
                //background: white;
                top: 62%;
                left: 7%;
                opacity: 0;
                transition: all 0.25s ease-out;
                z-index: 3;
                color: white;
                text-align: center;
                img {
                    max-width: 100%;
                    width: auto;
                    height: auto;
                    display: inline;
                }
            }

            .wedge {
                z-index: 2;
                position: absolute;
                left: -5%;
                bottom: -55%;
                width: 47%;
                height: 117%;
                background: #d40101;
                mix-blend-mode: multiply;
                // transform: translate3d(0,0,0);
                transform: rotate3d(0, 49, 126, 251deg) translate3d(-500px,0,0);
                opacity: 1;
                transition: all 0.8s;
            }
            
            .bg-image {
                transition: all 0.8s;
                transition-delay: 0.1s;
                /*opacity: 0; was used with extra hover image */
            }
            
            &:hover {
                .bg-image {
                    filter: none;
                    -webkit-filter: grayscale(0);
                    transition-delay: 0.1s;
                    transform: scale(1.1);
                    transition: all 0.8s;
                    /*opacity: 0; was used with extra hover image */
                }
                .wedge {
                    // opacity: 0.5;
                    transform: rotate3d(0, 49, 126, 251deg) translate3d(0,0,0);
                    transition: transform 0.8s;
                }
                .logo {
                    opacity: 1;
                    // top: 69%;
                    transition-delay: 0.38s;
                }
            }
        }
        .works-about {
            flex-direction: row;
            justify-content: space-between;
            margin-top: 20px;
            .texts {
                h3 {
                    margin-bottom: 10px;
                    @include RubrikNewLt();
                    font-size: 18px;
                    text-transform: uppercase;
                }
                p {
                    @include RubrikNewSb();
                    font-size: 28px;
                    text-transform: uppercase;
                }
            }
            .view {
                margin-right: 80px;
               &:hover {
                   p {
                       &:after {
                           transform: scaleX(1);
                           transform-origin: left;
                       }
                   }
                   span {
                       transform: translateX(10px);
                   }
               }
                a {
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    cursor: pointer;
                    p {
                        text-transform: uppercase;
                        @include OpenSansSb();
                        font-size: 13px;
                        color: #000;
                        margin-right: 15px;
                        &:after {
                            content: "";
                            position: absolute;
                            left: 0;
                            bottom: -6px;
                            background: #000;
                            height: 1px;
                            width: 100%;
                            transform-origin: right;
                            transform: scaleX(0);
                            transition: transform .8s cubic-bezier(.16, 1, .3, 1);
                        }
                    }
                    svg {
                        transform: rotate(-90deg);
                    }
                    span {
                        transform: translateX(0);
                        transition: transform .8s cubic-bezier(.16, 1, .3, 1);
                    }
                }

            }
        }
    }
}



@media screen and (max-width: 1440px) {
    .works {
        &-item {
            &:nth-of-type(2n+1) {
                margin-left: 25px;
            }
            &:nth-of-type(2n) {
                margin-right: 25px;

                .works-about {
                    margin-left: 65px;
                }
            }
        }
    }
}

@media screen and (max-width: 1240px) {
    .works {
        .line-text {
            position: relative;
            width: 100%;
            text-align-last: left;
        }

        &-items {
            margin-top: 63px;
        }
    }
}

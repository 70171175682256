.topper {
  position: relative;
  &-head {
    //align-items: center;
    
    .video-shape {
      width: 1448px;
      height: 100%;
      max-height: 816px;
      position: absolute;
      z-index: 1090;
      background-color: #fff;
    //   background-color: #1a1a1a;
      left: 50%;
      transform: translateX(-50%);
    }
    h1 {
      color: #fff;
      text-transform: uppercase;
      @include RubrikNewLt();
      font-size: 50px;
      text-align: center;
      span {
        color: red;
      }
    }
    .work-detail__topper {
      position: relative;
      width: 100.1%;
      height: 100%;
      overflow: hidden;
      .overlay {
        background-color: #000;
        opacity: 0.25;
        position: absolute;
        top: 0;
        left: 1px;
        right: -1px;
        bottom: 0;
        width: 99.99%;
        height: 100%;
      }
      .work-detail__video-topper {
        width: 100%;
        height: 100%;
        min-height: 679px;
        max-height: 100%;
        object-fit: cover;
      }
      .play-btn {
        pointer-events: all;
        cursor: pointer;
        position: absolute;
      
        width: 105px;
        height: 105px;
        border: solid 3px #fff;
        background-color: transparent;
        border-radius: 50%;
      
        transition: all .4s ease;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
      }
      .scroll-btn {
        position: absolute;
        bottom: 50px;
        width: 70px;
        height: 70px;
      
        border: 3px solid #fff;
        background-color: transparent;
        border-radius: 50%;
        cursor: pointer;
        transition: all .4s ease;
        z-index: 1080;
        &:hover {
          transition: all .4s ease;
          background-color: #d40101;
          border-color: #d40101;
        }
      }
  
      .thumbnail-desktop {
        display: block;
        width: 100%;
      }
      .thumbnail-mobile {
        display: none;
        width: 100%;
      }
  
      .player-wrapper {
        min-width: 100%;
        min-height: 100%;
        width: 100%;
        height: 100%;
      }
  
      .react-player {
        padding-top: 56.25%;
        position: relative;
      }
  
      .react-player > div {
        width: 100%;
        top: 0;
        bottom: 0;
        position: absolute;
      }
    }

    .scroll-btn {
      margin-bottom: 95px;
    }
  }
  &-about {
    text-align: center;
    padding: 90px 275px 90px;
    @include RubrikNewLt();
    color: #000000;
    
    @media screen and (max-width: 1280px) {
        padding: 90px 195px 90px;
    }
    
    &_text {
      position: relative;
      overflow: hidden;
    }
    h2 {
      text-align: center;
      font-size: 25px;
      line-height: 1.3;
    }
    h1 {
      font-size: 42px;
      line-height: 1.3;
      margin-bottom: 21px;
    }
    b {
      font-weight: bold;
    }
    i {
      font-style: italic;
    }
    a {
      color: #d40101;
    }

  }
}


.topper {
    &-head {
        .work-detail__topper {
            .scroll-btn {
                z-index: 1200;
            }
        }
    
    }
    &-about {
        padding: 70px 35px 90px;
    }
}



@media screen and (max-width: 480px) {
    .topper {
        &-about {
            padding: 70px 24.5px 115px;

            h2 {
                font-size: 30px;
            }
        }
    }
}

@media screen and (max-width: 414px) {
    .topper {
        &-about {
            padding: 55px 14px 85px;
            h2 {
                font-size: clamp(14px, 5vw, 20px);
                line-height: 1.28;
            }
        }
    }
}

@media screen and (max-width: 320px) {
    .topper {
        &-about {
            padding: 55px 10px 85px;
        }
    }
}
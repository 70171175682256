.privacy-section {

    .burger_menu {
        height: 104px;
    }
}

.privacy-view {
    padding: 250px 5% 95px;

    &_title {
        font-size: 40px;
    }
    &_date {
        font-size: 18px;
    }
    &_prompt {
        font-size: 14px;
        line-height: 1.6;
    }
    &_desc {
        font-size: 14px;
        line-height: 1.6;
    }

}

.terms {
    h6 {
        margin-top: 30px;
        font-size: 28px;
    }
    p {
        font-size: 14px;
        line-height: 1.6;
        
        u {
            width: 255px;
            margin-left: 25px;
            font-size: 14px;
        }
        i {
            font-size: 20px;
            line-height: 1.3;
        }
    }
    ul {
        margin-left: 25px;
        li {
            font-size: 14px;
        }
    }
}
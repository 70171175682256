.detail-loader {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1100;
    
    display: grid;
    
    .wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #141414;
    }
}

.standard-loader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2100;
    background-color: #d40101;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    width: 100vw;
    height: 100vh;
}

.work-detail__back-btn {
    position: fixed;
    top: 35px;
    margin-left: 50px;
    z-index: 1200;
    cursor: pointer;
    padding: 8px 31px;
    border-radius: 24px;;
    background-color: rgba(0, 0, 0, 0);
    
    &:hover {
        &:after {
            transform: scaleX(1);
            transform-origin: left;
        }
        span {
            transform: translateX(-10px);
        }
    }
    &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -6px;
        background: #000;
        height: 1px;
        width: 100%;
        transform-origin: right;
        transform: scaleX(0);
        transition: transform .8s cubic-bezier(.16, 1, .3, 1);
    }
    
    &.sticky {
        align-self: flex-start;
        background-color: rgba(0, 0, 0, 0.7);
        transition: background-color 1.25s ease;
        &::after {
            display: none;
        }
    }
    span {
        transform: translateX(0);
        transition: transform .8s cubic-bezier(.16, 1, .3, 1);
    }
    
    a {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;
        
        cursor: pointer;
        text-decoration: none;
        svg {
            transform: rotate(90deg);
        }
        p {
            margin-left: 15px;
    
            font-size: 14px;
            @include OpenSansSb();
            color: #fff;
            text-transform: uppercase;
        }
    }
    
}

.work-detail {
    position: relative;
    z-index: 10;
    @include RubrikNewRg();
    
    .richText-video {
        width: 100%;
    }
    .richText-img {
        width: 100%;
    }

    &.fix {
        margin-top: -48px;
    }
    
    .top {
        top: 40%;
    }
    &__video-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        top: -1px;
        .video-shape {
            width: 100%;
            height: 100%;
            max-height: 810px;
            position: absolute;
            z-index: 1090;
            pointer-events: none;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
                z-index: 1090;
                background-color: #fff;
            }
        }
    }
    
    &__topper {
        margin-top: -1px;
        position: relative;
        width: 100%;
    
        height: 100%;
        overflow: hidden;
        
        .overlay {
            background-color: #000;
            opacity: 0.25;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        .play-btn {
            cursor: pointer;
            position: absolute;
    
            width: 105px;
            height: 105px;
            border: solid 3px #fff;
            background-color: transparent;
            border-radius: 50%;
            transition: all .4s ease;
            pointer-events: all;
        }
        .scroll-btn-animate {
            width: 200px;
            height: 200px;
            display: none;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .scroll-btn {
            z-index: 1090;
            //position: absolute;
            //bottom: 110px;
            width: 70px;
            height: 70px;
            
            border: 3px solid #fff;
            background-color: transparent;
            border-radius: 50%;
            cursor: pointer;
            transition: all .4s ease;
            &:hover {
                transition: all .4s ease;
                background-color: #d40101;
                border-color: #d40101;
            }
        }
        .thumbnail-desktop {
            display: block;
            width: 100%;
        }
        .thumbnail-mobile {
            display: none;
            width: 100%;
        }
    
        .player-wrapper {
            min-width: 100%;
            min-height: 100%;
            width: 100%;
            height: 100%;
        }
    
        .react-player {
            padding-top: 56.25%;
            position: relative;
        }
    
        .react-player > div {
            width: 100%;
            top: 0;
            bottom: 0;
            position: absolute;
        }
        
    }
    
    
    
    &__video-topper {
        width: 100%;
        height: 100%;
        min-height: 694px;
        max-height: 100%;
        object-fit: cover;
    }
    
    &__black-columns {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding: 85px 65px;
        background-color: #000;
        color: #fff;
        box-sizing: border-box;
        margin-top: -1px;
        h1 {
            margin-bottom: 21px;
            font-size: 160px;
            @include OpenSansBl();
            color: #fff;
        }
        b {
            font-weight: bold;
        }
        i {
            font-style: italic;
        }
        a {
            color: #d40101;
        }
        .multiple {
            .paragraphs-column {
                flex: 1;
                &:last-child {
                    //margin-top: 30px;
                }
            }
        }
        .paragraphs {
            display: flex;
            gap: 120px;
            &-column {
                flex: 0 0 650px;
                &_no-title {
                    display: block;
                    margin-top: 65px;
                }
                &_title {
                    text-align: left;
                    text-transform: uppercase;
                    font-size: 36px;
                    position: relative;
                    @include RubrikNewSb();
                    line-height: 1;
                    padding-bottom: 10px;
                    margin-bottom: 21px;
                    display: inline-block;
                }
                .red-line {
                    &:before {
                        content: '';
                        bottom: 0;
                        left: 0;
                        position: absolute;
                        width: 40%;
                        height: 2px;
                        background-color: #f00;
                    }
                }
            }
            h2 {
                font-size: 36px;
                font-weight: 500;
                line-height: 1;
                margin-bottom: 21px;
            }
            h3 {
                font-size: 24px;
                font-weight: 500;
                line-height: 1.33;
                margin-bottom: 21px;
            }
            p {
                font-size: 16px;
                line-height: 1.75;
                margin-bottom: 20px;
            }
            
        }
        
    }
    .image-component {
        width: 100%;
        box-sizing: border-box;
        margin-top: -3px;
        img {
            width: 100%;
        }
        &__desktop {
            display: block;
        }
        &__mobile {
            display: none;
        }
    }
    &__automatic {
        position: relative;
        width: 100%;
    
        .play-btn {
            cursor: pointer;
            position: absolute;
        
            width: 105px;
            height: 105px;
            border: solid 3px #fff;
            background-color: transparent;
            border-radius: 50%;
        
            transition: all .4s ease;
            &:hover {
                transform: scale(1.2);
            }
        }
    }
    &__polaris-images {
        img {
            width: 100%;
        }
    }
    
    &__with-columns {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding: 75px 65px;
        background-color: #fff;
        box-sizing: border-box;
        h1 {
            font-size: 42px;
            font-weight: 500;
            line-height: 1;
            margin-bottom: 21px;
        }
        b {
            font-weight: bold;
        }
        i {
            font-style: italic;
        }
        a {
            color: #d40101;
        }
        .multiple {
            .paragraphs-column {
                flex: 1;
            }
        }
        .paragraphs {
            display: flex;
            gap: 120px;
            &-column {
                flex: 0 0 650px;
                &_no-title {
                    display: block;
                    margin-top: 65px;
                }
                &_title {
                    text-align: left;
                    text-transform: uppercase;
                    font-size: 21px;
                    position: relative;
                    @include RubrikNewSb();
                    line-height: 1;
                    padding-bottom: 10px;
                    margin-bottom: 21px;
                    display: inline-block;

                    &::after {
                        position: absolute;
                        content: '';
                        bottom: 0;
                        left: 0;
                        background-color: #d40101;
                        width: 40px;
                        height: 2px;
                    }
                }
                .red-line {
                    &:before {
                        content: '';
                        bottom: 0;
                        left: 0;
                        position: absolute;
                        width: 40%;
                        height: 2px;
                        background-color: #f00;
                    }
                }
            }
            h2 {
                font-size: 36px;
                font-weight: 500;
                line-height: 1;
                margin-bottom: 21px;
            }
            h3 {
                font-size: 24px;
                font-weight: 500;
                line-height: 1;
                margin-bottom: 21px;
            }
            p {
                @include OpenSansRg();
                font-size: 16px;
                line-height: 1.75;
                margin-bottom: 20px;
            }
        
        }
        
    }
    
    &__middle-video {
        width: 100%;
        background-color: #000;
        img{
            width: 100%;
        }
        .video-block {
            width: 100%;
            max-width: 1024px;
            position: relative;
            .full-video-topper {
                width: 100%;
                height: 100%;
                max-height: 100%;
                min-height: 577px;
                object-fit: cover;
            }
            .video-middle {
                width: 100%;
                height: 100%;
                min-height: 577px;
                max-height: 100%;
                object-fit: cover;
            }
            .play-btn {
                cursor: pointer;
                position: absolute;
        
                width: 105px;
                height: 105px;
                border: solid 3px #fff;
                background-color: transparent;
                border-radius: 50%;
        
                transition: all .4s ease;
                &:hover {
                    transform: scale(1.2);
                }
            }
        }
    }
    &__quote {
        padding: 180px 50px 50px 50px;
        h2 {
            text-align: center;
            position: relative;
            font-size: 28px;
            @include OpenSansRg();
            color: #141414;
            margin-bottom: 30px;
            line-height: 1.5;
            letter-spacing: 1.5px;
            &:before {
                content: '"';
                position: absolute;
                top: -150px;
                left: 50%;
                transform: translate(-50px, 0);
                font-style: italic;
                font-size: 150px;
                color: #db0400;
            }
        }
        span {
            font-size: 16px;
            @include OpenSansBl();
            color: #141414;
        }
    }
    &__carousel {
        width: 100%;
        position: relative;
        .carousel-wrapper {
            &:hover {
                .arrows {
                    transition: all .4s ease;
                    opacity: 1;
                }
            }
            .keen-slider {
                padding-bottom: 80px;
    
                transform:matrix(0.99,-0.10,0.10,0.99,0,37);
                -ms-transform:matrix(0.99,-0.10,0.10,0.99,0,37);
                -webkit-transform:matrix(0.99,-0.10,0.10,0.99,0,37);
                &__slide {
                    cursor: pointer;
                    .img-wrapper {
                        padding-bottom: 50px;
                        position: relative;
                        width: 100%;
                        max-width: 475px;
                        overflow: hidden;
                    }
                    img {
                        display: block;
                        width: 100%;
                        transition: all .4s ease;
                    }
                    .truncated-text {
                        position: absolute;
                        bottom: -10px;
                        padding: 25px 50px 50px 25px;
                        max-width: 300px;
                        background-color: #000;
                        p {
                            font-size: 16px;
                            color: #fff;
                            @include OpenSansRg();
                            line-height: 1.4;
                        }
                    }
                }
            }
            .arrows {
                opacity: 1;
                .arrow-wrapper-left {
                    right: 70px;
                }
            
                .arrow-wrapper-right {
                    right: 20px;
                }
            
                .arrow-wrapper {
                    position: absolute;
                    bottom: 30px;
                    width: 70px;
                    height: 70px;
                    align-items: center;
                    display: inline-flex;
                    justify-content: center;
                    
                    cursor: pointer;
                    .arrow--left {
                        height: 20px;
                        transform: rotate(180deg);
                    }
                    .arrow--right {
                        height: 20px;
                        transform: rotate(0deg);
                    }
                }
            }
            .dots {
                display: flex;
                gap: 15px;
                justify-content: center;
                align-items: center;
                margin-top: 55px;
                margin-bottom: 60px;
                .dot {
                    cursor: pointer;
                    padding: 4px;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: transparent;
                    border: solid 1px #000000;
                }
                .active {
                    padding: 4px;
                    border: solid 1px #d40101;
                    background-color: #d40101;
                }
            }
        }
    }
    .slides-modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1290;
        width: 100vw;
        height: 100vh;
        &__close-btn {
            position: absolute;
            top: 30px;
            right: 20px;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            border: none;
            background-color: rgba(0, 0, 0, .5);
            z-index: 1051;
            cursor: pointer;
            svg {
                width: 35px;
                height: 35px;
            }
        }
        &__wrapper {
            width: 100%;
            height: 100%;
            .keen-slider {
                height: 100%;
                width: 100%;
                transform: none;
                .keen-slider__slide {
                    height: 100%;
                    width: 100%;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    img {
                        width: 100%;
                        max-width: 100%;
                    }
                    .truncated-text {
                    
                    }
                }
            }
            .arrows {
                .arrow-wrapper {
                    top: 45%;
                    border-radius: 50%;
                    background-color: #d40101;
                }
                .arrow-wrapper-left {
                    left: 20px;
                }
            }
        }
    }
    &__video-block-penultimate {
        width: 100%;
        background-color: #000;
        padding-bottom: 50px;
        img{
            width: 100%;
        }
        .video-block {
            width: 100%;
            max-width: 1024px;
            position: relative;
            .full-video-topper {
                width: 100%;
                height: 100%;
                max-height: 100%;
                min-height: 577px;
                object-fit: cover;
            }
            .video-middle {
                width: 100%;
                height: 100%;
                min-height: 577px;
                max-height: 100%;
                object-fit: cover;
            }
            .play-btn {
                cursor: pointer;
                position: absolute;
            
                width: 105px;
                height: 105px;
                border: solid 3px #fff;
                background-color: transparent;
                border-radius: 50%;
            
                transition: all .4s ease;
                &:hover {
                    transform: scale(1.2);
                }
            }
        }
    }
    &__video-block-last {
        @extend .work-detail__video-block-penultimate;
    }
    
    &__impact {
        width: 100%;
        padding: 100px 70px;
        align-items: flex-start;
        color: #fff;
        background-color: #000;
        box-sizing: border-box;
        
       .impact-title {
            align-self: flex-start;
            position: relative;
            font-size: 21px;
            font-weight: 500;
            padding-bottom: 5px;
            &:before {
                content: '';
                bottom: 0;
                left: 0;
                position: absolute;
                width: 30%;
                height: 2px;
                background-color: #f00;
            }
        }
        
        .impact-about {
            display: flex;
            align-items: center;
            gap: 100px;
            p {
                line-height: 1.44;
                max-width: 600px;
                font-size: 18px;
            }
            h3 {
                font-size: 160px;
                font-weight: bold;
                margin-bottom: 10px;
                
            }
            p {
                font-size: 18px;
                font-weight: bold;
            }
        }
    }
    
    &__stats {
        width: 100%;
        position: relative;
        padding: 45px 65px 100px;
        box-sizing: border-box;
        .outcomes-title {
            align-self: flex-start;
            position: relative;
            font-size: 21px;
            font-weight: 500;
            padding-bottom: 5px;
            margin-bottom: 55px;
            &:before {
                content: '';
                bottom: 0;
                left: 0;
                position: absolute;
                width: 30%;
                height: 2px;
                background-color: #f00;
            }
        }
        
        ul {
            width: 100%;
            display: flex;
            gap: 100px;
            justify-content: flex-start;
            
            li {
                @include OpenSansBl();
                color: $deepdark;
                h4 {
                    line-height: 1.33;
                    font-size: 120px;
                }
                
                p {
                    font-size: 18px;
                    line-height: 1.78;
                    text-transform: uppercase;
                }
            }
        }
    }
    
    .back-section {
        position: absolute;
        bottom: 30px;
        
        width: 100%;
        padding: 50px 70px;
        align-items: flex-start;
        box-sizing: border-box;
        z-index: 1200;
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
        
            cursor: pointer;
            text-decoration: none;
            svg {
                transform: rotate(90deg);
            }
            p {
                font-size: 14px;
                @include OpenSansSb();
                color: #000;
                text-transform: uppercase;
                margin-left: 15px;
            }
        }
    }
}


@media screen and (max-width: 428px) {
    .accordion {
        &_title {
            font-size: 18px;
        }

        &_btn-close {
            top: -11px;
            svg {
                width: 32px;
            }
        }
    }
}
.animated {
    animation: headerAnimate .8s ease-in;
    transition: all .4s ease-in;
}

@keyframes headerAnimate {
    from {
        transform: translateY(-200px);
    }
    
    to {
        transform: translateY(0px);
    }
}

.scrolled {
    top: 0;
    left: 0;
    position: fixed!important;
    background-color: #000;
    max-width: 1440px;
    right: 0;
    .header-nav {
        max-width: 1440px;
        margin: 0 auto;
        padding: 25px 65px 15px;
        transition: padding .4s ease;

        ul {
            padding-top: 25px;
            transition: padding-top .8s ease;
            align-items: flex-start;
        }
    }
    
    .header-logo {
        a {
            svg {
                width: 120px;
                transition: width .8s ease;
            }
        }
    
        .red-logo-line {
            width: 186px !important;
            transition: width .8s ease;
        }
    }
    
    
}
.header {
    position: absolute;
    width: 100%;
    margin: 0 auto;
    z-index: 10;
    &-logo {
        
        a {
            display: block;
        }

        svg {
            width: 164px;
            transition: width .8s ease;
        }
        
        .red-logo-line {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 234px;
            height: 6px;
            background-color: #d40101;
            transition: width .8s ease;
        }
        
    }

    &-nav {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 50px 64px;
        transition: padding .8s ease;

        ul {
            position: relative;
            display: flex;
            justify-content: space-between;
            padding-top: 0px;
            transition: padding-top .8s ease;
            cursor: pointer;

            li {
                position: relative;
                height: fit-content;
                margin-left: 40px;
                padding-bottom: 12px;

                &:first-child {
                    margin-left: 0;
                }

                &.active{
                    width: 100%;
                    height: 4px;
                    top: 20px;
                    position: absolute;
                    background-color: #d40101;
                }
                
                .underbar {
                    width: 100%;
                    height: 4px;
                    top: 21px;
                    position: absolute;
                    background-color: #d40101;
                }
            }

            a {
                position: relative;
                font-size: 14px;
                letter-spacing: 1.26px;
                @include RubrikNewSb();
                text-transform: uppercase;
                text-decoration: none;
                color: #ffffff;
                padding-bottom: 10px;
            }
        }
    }

    .burger_menu {
        display: none;
    }
}

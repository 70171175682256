.works {
    .line-text {
        &:before {
            width: 45px;
        }
    }
    &-item {
        margin-bottom: 78px;
        &:last-child {
            margin-bottom: 0;
        }
    
        &:nth-of-type(2n+1) {
            .works-about {
                margin-right: 25px;
            }
        }

        &:nth-of-type(2n) {
            .works-about {
                margin-left: 25px;
            }
        }


        .works-about {
            margin-top: 15px;

            .texts {
                h3 {
                    text-transform: capitalize;
                }
                p {
                    text-transform: capitalize;
                }
            }

            .view {
                margin-right: 0;
                margin-left: 25px;

                a {
                    p{
                        display: none;
                    }

                    svg {
                        width: 32px;
                        height: 32px;
                    }
                }
            }
        }
    }
}



@media screen and (max-width: 480px) {
    .works {
        padding-bottom: 105px;
        &-item {
            .works-about {
                .view {
                    margin-left: 0;
                    margin-right: 20px;
                }
            }
        }
        .btn-route {
            margin-top: 61.5px;
            font-size: clamp(16px, 5vw, 21px);
            font-weight: 500;
            border-width: 2px;
            padding: 24.5px 52px;
        }
    }
}

@media screen and (max-width: 375px) {
    .works {
        &-item {
            margin-bottom: 60px;

            &:nth-of-type(2n+1) {
                margin-left: 15px;
                .works-about {
                    margin-right: 15px;
                }
            }

            &:nth-of-type(2n) {
                margin-right: 15px;
                .works-about {
                    margin-left: 15px;
                }
            }
            .works-about {
                .texts {
                    h3 {
                        font-size: 16px;
                    }
                    p {
                        font-size: 24px;
                    }
                }
            }
        }
    }
}

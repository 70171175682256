.work-detail {
    .top {
        top: 50%;
        width: 105px;
        height: 105px;
        transform: translateY(-50%);

        @media screen and (max-width: 375px) {
            width: 85px;
            height: 85px;
        }

        .btn-border-anim {
            @media screen and (max-width: 375px) {
                width: 80px !important;
                height: 80px !important;
            }
        }
    }
    
    &__video-wrapper {
        .video-shape { display: none; }
    }

    &__topper {
        .play-btn {
            z-index: 1200;
        }
        .scroll-btn-animate {
            z-index: 1200;
            .scroll-btn {
                z-index: 1300;
                border: 2px solid #fff;
    
                @media screen and (max-width: 375px) {
                    width: 65px;
                    height: 65px;
                }
            }
        }
        
        .player-wrapper {
            position: relative;
            //z-index: 10000;
        }
    }
    &__quote {
        padding: 132px 50px 50px 50px;

        @media screen and (max-width: 414px) {
            padding: 132px 35px 50px;
        }

        h2 {
            font-size: 26px;
            letter-spacing: 0;
            line-height: 1.3;
            @media screen and (max-width: 428px) {
                font-size: clamp(15px, 5vw, 22px);
                line-height: 1.3;
            }
            &::before {
                top: -150px;
                font-size: clamp(96px, 25vw, 114px);
                transform: rotate(180deg);
                font-weight: bold;
            }
        }
    }
    
    &__video-topper {
        min-height: inherit;
    }
    &__black-columns {
        padding: 84px 31px 25px;
        margin-top: 64px;
        @media screen and (max-width: 428px) {
            padding: 80px 20px 25px;
        }

        &:nth-child(2) {
            margin-top: -1px;
            padding: 90px 40px 93px;
            @media screen and (max-width: 428px) {
                padding: 80px 20px 83px;
            }
        }

        .paragraphs-column_title {
            font-size: clamp(30px, 7.6vw, 36px);
        }

        h1 {
            font-size: clamp(112px, 33vw, 160px);
        }
        .paragraphs {
            gap: 20px;
            h3 {
                font-size: clamp(17px, 5.1vw, 24px);
                line-height: 1.33;
            }

            p {
                font-size: clamp(14px, 4.2vw, 18px);
                line-height: 1.44;
            }
            &-column {
                &_no-title {
                    margin-top: 0;
                }
            }

        }
    }

    &__with-columns {
        padding: 52px 32px 83px;

        @media screen and (max-width: 428px) {
           padding: 52px 22px 83px;
        }
        .paragraphs-column {
            flex: 100%;

            p {
                font-size: clamp(15px, 4.2vw, 18px);
            }
        }
    }

    .image-component {
        &__desktop {
            display: none;
        }

        &__mobile {
            display: block;
        }

        &:nth-of-type(4) {
            margin-top: -2px;
        }

        &:nth-of-type(6) {
            // display: none;
        }
    }
    
    .back-section {
        bottom: 13px;
        padding: 0 32px 65px;
        
        a{
            padding: 9px 22px;
            border-radius: 24px;
            background-color: rgba(0, 0, 0, 0.7);
            p {
                color: #fff;
                margin-left: 0px;
            }
        }
    }
    
    .carousel-wrapper {
        padding-bottom: 20px;
        .keen-slider {
            transform: none;
            padding-bottom: 40px;
        }
        .dots {
            display: none;
        }
    
        .arrows {
            display: flex;
            justify-content: center;
            opacity: 1;
            .arrow-wrapper {
                position: inherit;
                background-color: transparent;
                .arrow--left {
                    height: 100%;
                    width: 50%;
                }
                .arrow--right {
                    height: 100%;
                    width: 50%;
                }
                svg {
                    g {
                        fill: #d40101;
    
                    }
                }
            }
        }
    }
    .slides-modal {
        &__close-btn {
            top: 20px;
            right: 20px;
        }
    }
    &__stats {
        padding: 64px 32px 134px;
        
        ul {
            li {
                max-width: 310px;
                h4 {
                    font-size: clamp(65px, 25vw, 120px);
                }
                p {
                    font-size: clamp(14px, 4.2vw, 18px);
                    line-height: 1.35;
                }
            }
        }
    }
}
.team {
    &-about {
        .numbers {
            // margin-top: 40px;
            // grid-gap: 110px;

            @media screen and (max-width: 880px) {
                grid-gap: 0;
                flex: 100%;
                justify-content: space-between;
            }
        }

         .line-text {
            margin-right: 125px;
            width: 100%;
        }
    }
}
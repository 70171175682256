.page_body {
    position: relative;
}

.news-detail {
    position: relative;

    &_head {
        padding: 0 48px;
    }

    &_body {
        padding: 0 48px;
        z-index: 30;
        background-color: #fff;
    }

    &_view {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 158px 0px 95px;
        position: relative;
    }

    &_date {
        display: block;
        width: 100%;
        font-size: 14px;
        @include OpenSansRg();
        color: #000;
        text-align: center;
        text-transform: uppercase;
    }

    &_title {
        padding: 0 140px;
        margin-top: 8px;
        text-align: center;
        opacity: 1;
    }

    &_image {
        position: relative;
        margin-top: 53px;
        max-height: 756px;
        // width: 100%;
        height: 100%;
        padding: 0 48px;

        img {
            width: 100%;
            height: 100%;
            max-width: 100%;
        }
    }

    .btn-route {
        display: none;
    }

    .news {
        padding-top: 0;
        
        &::before {
            content: '';
            display: block;
            background-color: #cfcfcf;
            height: 1px;
            width: 100%;
        }

        &-title {
            margin-top: 115px;
        }
    }
}

.article_social {
    position: absolute;
    top: 53px;
    left: 50px;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
    
    span {
        @include RubrikNewRg();
        font-size: 16px;
        color: #000;
        text-transform: uppercase;
    }
    
    li {
        margin-top: 25px;
        
        img {
            transform: scale(1);
            transition: transform 0.5s ease-in-out;
            
            &:hover {
                transform: scale(1.5);
                transition: transform 0.4s ease-in-out;
            }
        }
    }
}

.article {

    &_share-box {
        position: relative;
        margin-top: 53px;
    }
    
    &_text {
        padding-left: 241px;
        padding-right: 241px;

        h6 {
            margin-top: 35px;
            @include OpenSansRg();
            font-size: 24px;
            line-height: 1.33;
            color: #000;
        }

        p {
            margin-top: 15px;
            @include OpenSansRg();
            font-size: 16px;
            line-height: 1.5;
            color: #000;

            a {
                font-size: inherit;
                color: #db0400;
                text-decoration: none;
            }
        }
    
        .blockquote {
          b {
            @include RubrikNewSb();
          }
        }
        
    }
}

.article-media {
    &_wrap {
        display: flex;
        justify-content: center;
        margin: 80px auto;
        width: 100%;
        max-width: 1095px;

        .work-detail__topper {
        }

        .play-btn {
            width: 80px;
            height: 80px;

            .btn-border-anim {
                width: 80px !important;
                height: 80px !important;

            }

            &.top {
                top: 40%;
            }
        }
    }
}

.article_after-video {
    p {
        &:first-child {
            margin-top: 0;
        }
    }
}

.more-text {
  display: flex;
  flex-direction: column;
  p {
    @include OpenSansRg();
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: normal;
    color: #c4c4c4;
    margin-bottom: 10px;
  }
  span {
    @include OpenSansRg();
    font-size: 16px;
    color: #db0400;
  }
  
  .read-or-hide {
    cursor: pointer;
  }
}

.modal {
    .team-detail_about {
        .description-block {
            overflow-y: scroll;
            padding: 45px 25px 40px;
        }
    
        .img-block {}
    
        .description-block_works {
            .works-item {
                margin-left: 0;
            }
        }
    }
}

.more-work-section {
    position: relative;
    padding: 85px 0;
    margin: 0 66px;
    border-top: 1px solid #ccc;
    width: 100%;
    z-index: 20;
    h1 {
        margin-bottom: 40px;
        display: inline-block;
        position: relative;
        text-transform: uppercase;
        font-size: 21px;
        font-weight: 500;
        &:after {
            content: "";
            display: block;
            margin-top: 2px;
            height: 2px;
            width: 47px;
            background-color: #d40101;
        }
    }
    .work-items {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        grid-gap: 30px;
        width: 100%;
        a {
            display: block;
            text-decoration: none;
        }
        .work-item {
            .work-image {
                margin-bottom: 33px;
                max-width: 416px;
                width: 416px;
                background-position: center;
                background-size: cover;
                max-height: 200px;
                height: 200px;
            }
            .work-text {
                color: #000;
                text-transform: uppercase;
                h3 {
                    font-size: 18px;
                    margin-bottom: 7px;
                }
                p {
                    font-size: 28px;
                    font-weight: 500;
                }
            }
        }
    
    }
}

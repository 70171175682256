.news-detail {

    &_title {
        padding: 0 75px;
    }
}


.article {
    
    &_text {
        padding-left: 110px;
        padding-right: 110px;
    }
}

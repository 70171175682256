.new-news {
    .page_view {
        padding: 85px 29.5px 86px;
    }

    .page_title {
        font-size: 52px;
    }

    &_view {
        padding-top: 41px;
    }
    .new-news-tips {
        gap: 10px;
        margin: 0;
    }

    .new-news-result {
        margin-top: 63px;
        position: relative;
        
        .no-matches {
            position: absolute;
        }

        .btn-route {
            margin-top: 80px;
            font-size: 21px;
            font-weight: 500;
            border-width: 2px;
            padding: 21.5px 52.8px;
        }
    }
    
    .grid-list {
        grid-template-columns: repeat(auto-fill, minmax(295px, 1fr));
        gap: 60px 15px;

        .grid-image {
            img {
                max-height: 338px;
            }
        }

        .grid-text {
            h3 {
                font-size: 24px;
                text-transform: none;
            }
        }
    }
}


@media screen and (max-width: 480px) {
    .new-news {
        .new-news-tips {
            padding-top: 35px;
        }
        .page_view {
          padding: 85px 27px 57px
        }
        .page_title {
            font-size: clamp(36px, 11vw, 52px);
        }
        .grid-list {
            grid-template-columns: auto;
            gap: 40px;

            .grid-text {
                margin-top: 18px;
            }
            .grid-image {
                img {
                    max-height: 100%;
                    height: auto;
                }
            }
        }
        .new-news-result {
          margin-top: 48px;
        }
        .new-news-tips {
          padding-top: 35px;
          row-gap: 21px;

          &_wrapper {
            padding: 8px 19px;
          }
        }

        .accordion {
          margin-top: 46px;
          &_btn-close {
            display: none;
          }
        }
    }
}

@media screen and (max-width: 428px) {
    .new-news {

        .grid-list {
            grid-template-columns: 1fr;
            gap: 50px;

            .grid-text {
                h3 {
                    font-size: 18px;
                }
            }
        }

        .new-news-tips {
            gap: 8px;

            &_wrapper {
                padding: 6.5px 10.5px;
                border-radius: 22.5px;
            }
            &_tip {
                font-size: 12px;
            }
        }
    }
}
.scrolled {
    .burger_menu {
        height: 87px!important;
        z-index: 1100!important;
        &::before {
            transition: all .4s ease;
            width: 139px!important;
        }
        .header-logo {
            svg {
                width: 115px!important;
                height: 44px!important;
                transition: all .4s ease;
            }
        
        }
    }
    
}

.header {
    z-index: 1250;
    transition: all .4s ease;
    
    &.hide {
        transform: translateY(-100%);
        transition: transform .4s ease;
    }
    
    &-nav {
        display: none;
    }

    &-logo {
        &::before {
            content: none;
        }
    }

    .burger_menu {
        position: relative;
        display: block;
        height: 58px;
        z-index: 1;
        @include RubrikNewSb();
        transform: translateY(0%);
            transition: transform .4s ease;
    
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 186px;
            height: 5px;
            background-color: #d40101;
            z-index: 10;
            transition: all .4s ease;
        }

        &.hide {
            transform: translateY(-100%);
            transition: transform .4s ease;
        }

        &.fixed {
            position: fixed;
            height: auto;
        }

        
        .header-logo {
            position: absolute;
            top: 22px;
            left: 24px;
            z-index: 10;

            svg {
                width: 164px;
                height: 62px;
                transition: all .4s ease;
            }
            
        }
    
        &-icon {
            display: block;
            width: 40px;
            height: 22px;
            position: absolute;
            top: 32px;
            right: 23px;
            cursor: pointer;
            z-index: 11;

            &.open {
                height: 30px;
                width: 40px;
                animation: smallbig 0.6s forwards;

                @keyframes smallbig{
                    0%, 100%{
                        transform: scale(1);
                    }

                    50%{
                        transform: scale(0);
                    }
                }
                
                span:nth-child(1),
                span:nth-child(2),
                span:nth-child(3) {
                    transition-delay: 0.2s;
                }
                
                span:nth-child(2) {
                    opacity: 0;
                }
                
                span:nth-child(1) {
                    transform: translateY(13px) rotate(45deg);
                }
                span:nth-child(3) {
                    transform: translateY(-3px) rotate(-45deg);
                }
            }

            span {
                display: block;
                position: absolute;
                height: 2px;
                width: 100%;
                // margin: 7px 0;
                background: #fff;
                border-radius: 1.2px;
                opacity: 1;
                transition: all 0.3s ease-in-out;

                &:first-child {
                    margin-top: 0;
                }
                &:nth-of-type(1) {
                    top: 2px;
                }
                &:nth-of-type(2) {
                    top: 11px;
                }
                &:nth-of-type(3) {
                    top: 20px;
                }
            }

            &.open {
                + .burger_menu-nav {
                    transform: none;

                    li {
                        opacity: 1;
                        transform: translateX(0%);

                        &:first-child {
                            transition-delay: .25s;
                        }
                        &:nth-of-type(2) {
                            transition-delay: .35s;
                        }
                        &:nth-of-type(3) {
                            transition-delay: .45s;
                        }
                        &:nth-of-type(4) {
                            transition-delay: .55s;
                        }
                        &:last-child {
                            transition-delay: .65s;
                        }
                    }
                }
                
                span {
                    
                    &:nth-of-type(3) {
                        top: 18px;
                    }
                }
            }
        }
    
        &-nav {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100vw;
            height: 100vh;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            -webkit-font-smoothing: antialiased;
            transform-origin: 0% 0%;
            transform: translate(0, -100%);
            transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
            z-index: 1300;
            overflow-y: scroll;
    
            ul {
                li {
                    &.active{
                        width: 100%;
                        height: 4px;
                        top: 20px;
                        position: absolute;
                        background-color: #d40101;
                    }
            
                    .underbar {
                        width: 100%;
                        height: 4px;
                        bottom: 0;
                        position: absolute;
                        background-color: #d40101;
                    }
                }
            }
        }

        .nav-list {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            padding-right: 35px;
            margin-top: 30px;
            
            @media screen and (max-width: 321px) {
                margin-top: 60px;
            }

            &_item {
                margin-top: 35px;
                opacity: 0;
                transform: translateX(0%);
                transition: opacity .7s ease-in;

                &:first-child {
                    margin-top: 0;
                }
            }

            &_link {
                position: relative;
                padding-bottom: 5px;
                font-size: 32px;
                @include RubrikNewSb();
                color: #fff;
                letter-spacing: 2.88px;
                text-transform: uppercase;
                text-decoration: none;

                @media screen and (max-width: 321px) {
                    font-size: 22px;
                    padding-bottom: 2px;
                }
                
                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 3px;
                    background-color: #ff0000;
                    transform-origin: left top;
                    transform: scaleX(0);
                    transition: transform .2s ease-out;
                }

                &:hover{
                    &:after {
                        transform-origin: right top;
                        transform: scale(1);
                    }
                }
            }
        }

        &-social {
            margin-top: 30px;
            padding: 0 35px 0;
            
            .social-nav {
                display: flex;
                flex-direction: column;
                justify-items: flex-start;
                align-items: flex-start;

                &_item {
                    margin-top: 27px;

                    &:first-child {
                        margin-top: 0;
                    }

                    
                }

                &_link {
                    display: block;

                    img {
                        width: 28px;
                        height: 28px;
                    }

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.btn-route {
    display: inline-block;
    min-width: 182px;
    padding: 16px 28px;
    border: 3px solid $red;
    border-radius: 44px;
    color: $red;
    background-color: transparent;
    @include RubrikNewBd();
    font-size: 13px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    box-sizing: border-box;
    transition: ease-in-out .45s;
    cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }

  &_about {
    margin: 35px 0 3px;
  }

  &_news-list {
      margin-top: 80px;
  }

  &_team {
      margin-top: 70px;
  }

  &_work-list {
      margin-top: 70px;
  }
}

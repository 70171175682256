.grid-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    width: 100%;

    &_item {
        a {
            width: 100%;
            height: 100%;
            text-decoration: none;
        }
    }

    .grid-image {
        img {
            width: 100%;
            max-width: 100%;
            object-fit: cover;
        }
    }

    .grid-text {}
}
.careers {
    padding: 132px 24px 95px;
    
    .page_title {
        font-size: 52px;
    }

    .page_description {
        margin-top: 36px;
        font-size: 18px;
        line-height: 1.78;
    }
}

.careers-filters {
    margin-top: 0;

    iframe {
        width: 100%;
        height: 590px;
    }
}

.careers-more {
    max-width: 100%;
    h6 {
        font-size: 36px;
    }

    span {
        margin-top: 30px;
    }
}


@media screen and (max-width: 480px) {
    .careers {
        padding: 132px 24px 0;
        .page_title {
            font-size: clamp(36px, 11vw, 52px);
        }

        .page_description {
            font-size: clamp(15px, 3.8vw, 18px);
        }
    }

    .careers-filters {
        iframe {
            width: 100%;
            height: 550px;
        }
    }
    .careers-more {
        display: none;
    }
}
@media screen and (max-width: 428px) {
    .careers {
        padding: 132px 15px 0px;
    }
}
 .works {
    &-item {
        &:nth-of-type(2n) {
            .works-about {
                margin-left: 45px;
            }
        }

        .frame {            
            .logo {
                // opacity: 1;
                // top: 40%;
                // left: 0;
                display: none;
            }

            .wedge {
                display: none;
            }
        }

        .works-about {

        } 
    }
}
.page {

    .header{
        .burger_menu {
            height: 104px;
        }
    }
    
    &_view {
        padding: 85px 20px;
    }

    &_title {
        font-size: 32px;
    }

    &_description {
        max-width: 100%;
        font-size: 16px;
    }
}

@media screen and (max-width: 480px) {
    .no-matches {
        width: 60%;
        font-size: 16px;
        left: 50%;
        transform: translateX(-50%);
        line-height: 1.25;
    }
}

@media screen and (max-width: 415px) {
    .page {
        &_title {
            font-size: 25px;
        }
    }
    .no-matches {
        width: 70%;
    }
}
.page {

    &_view {
        padding: 105px 25px;
    }

    &_title {
        font-size: 42px;
    }

    &_description {
        max-width: 75%;
        font-size: 16px;
    }
}
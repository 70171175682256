.work-detail__back-btn {
    margin-left: 10px;
}

.work-detail {
    &__black-columns {
        // padding: 85px 100px;

        &:nth-of-type(3) {
            .paragraphs-column {
                &:nth-of-type(2) {
                    margin-top: 0;
                }
            }
        }

        .paragraphs {
            flex-direction: column;
            grid-gap: 20px;
            &-column {
                flex: 1;
            }
            h3 {
                line-height: 1.53;
            }
        }
        #test {
            .paragraphs-column_title {
                font-size: 21px;
            }
        }
    }

    .slides-modal {
        &__close-btn {
            right: 2px;
        }
    }
    &__stats {
        padding: 45px 70px 100px;
        .outcomes-title {
            margin-bottom: 35px;
        }
        ul {
            gap: 50px;
            flex-direction: column;
        }
    }
}

.careers {
    padding: 185px 65px 95px;
}

.careers-filters {
    margin-top: 45px;
    iframe {
        position: relative;
        width: 100%;
        height: 850px;
    }
}

.careers-more {
    margin-top: 10px;
    max-width: 45%;

    h6 {
        font-size: 36px;
        @include RubrikNewRg();
        color: #000;
    }

    span {
        display: block;
        margin-top: 40px;
        font-size: 21px;
        @include OpenSansRg();
        color: #000;
    }

    a {
        color: #db0400;
        text-decoration: none;
    }
}

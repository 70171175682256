.footer {
     padding: 0 22px 55px;
    .lane-width {
        width: auto;
    }
    .lanebar {
        margin-top: 0;
    }
}

@media screen and (max-width: 480px) {
    .footer {
        padding: 0 22px 28px;
        .contactpad {
            a {
                margin-top: 5px;
            }
        }
        .lanebar-contact {
            .contacts {
                gap: 32px 60px;
            } 
        }
        .lanebar-social {
            margin-top: 87px;
            li {
                a {
                    font-size: 16px;
                    img {
                        width: 26px;
                        height: 26px;
                    }
                }
            }
        }

        &-copy {
            margin-top: 65px;
        }
    }
}

@media screen and (max-width: 415px) {
    .footer {
        .address {
        
            &-lane {
                gap: 50px 36px;
                &_item {
                    
                    &-location {
                        width: 140px !important;
                        
                    }
                }
                
            }

        }

        .lanebar-contact {
            .contacts {
                gap: 50px 20px;
                .contactpad {
                    width: 155px;
                    p {
                        letter-spacing: -0.3px;
                    }
                }
            }
        }
    }
}

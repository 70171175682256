.more-news-section {
    padding: 60px 0;
    margin: 0 29.5px;
    width: initial;
    margin-top: 20px;
    
    @media screen and (max-width: 320px) {
        margin: 0 22px;
    }
    .news-items {
        grid-gap: 60px;

        @media screen and (max-width: 428px) {
            grid-gap: 30px;
        }
        .news-item {
            width: 100%;
            .news-image {
                max-width: 421px;
                max-height: 249px;
                height: 249px;
                width: initial;
                margin-bottom: 18px;

                @media screen and (max-width: 428px) {
                    height: 210px;
                }
                @media screen and (max-width: 375px) {
                    height: 185px;
                }
                @media screen and (max-width: 320px) {
                    height: 160px;
                }
            }
            
        }
    }
}


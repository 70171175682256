.footer {
    display: flex;
    flex-direction: column;
    padding: 0 65px 85px;

    &::before {
        content: '';
        display: block;
        background-color: $gray;
        height: 1px;
        width: 100%;
    }
    
    &-title {
        flex: 1;
        font-size: 21px;
        @include RubrikNewSb();
        color: $deepdark;
        text-transform: uppercase;

        &::after {
            content: "";
            display: block;
            margin-top: 4px;
            height: 2px;
            width: 100px;
            background-color: $red;
        }
    }

    &-info {
        display: flex;
        flex-direction: column;
        padding-top: 70px;
    }

    &-copy {
        margin-top: 90px;
        font-size: 13px;
        @include RubrikNewRg();
        color: $deepdark;
        text-align: center;

        span {
            display: inline-block;
            font-size: 7px;
            margin: 0px 4px;
            padding-bottom: 3px;
            vertical-align: middle;
        }
    }

    &-privacy {
        font-size: 13px;
        @include RubrikNewRg();
        color: $deepdark;
        text-decoration: none;
        &:hover {
            color: #d40101;
        }
    }

    .lane-width {
        width: 920px;
    }

    .lanebar {
        display: flex;
        justify-content: space-between;
        margin-top: 55px;

        &-contact {
            .contacts {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                gap: 50px 45px;
                max-width: 580px;
            }
        }

        &-social {
            li {
                a {
                    display: flex;
                    align-items: center;
                    font-size: 13px;
                    @include RubrikNewRg();
                    color: $deepdark;
                    text-decoration: none;

                    &:hover {
                        color: $deepdark;
                        text-decoration: none;
                    }

                    img {
                        margin-right: 10px;
                        width: 21px;
                        height: 21px;
                        transition: all 0.35s ease-in-out;

                        &:hover {
	                        transform: scale(1.5); 
                        }
                    }
                }

                &:nth-of-type(n+2) {
                    margin-top: 25px;
                }
                &:last-child {
                    margin-bottom: 25px;
                }
            }
        }
    }

    .address {
        display: flex;
        flex-wrap: wrap;

        &-lane {
            display: flex;
            gap: 50px 45px;
            &_item {
                h4 {
                    font-size: 18px;
                    @include RubrikNewSb();
                    color: $deepdark;
                    text-transform: uppercase;
                }
                span {
                    display: block;
                    margin-top: 30px;
                    font-size: 15px;
                    @include RubrikNewRg();
                    color: $deepdark;
                    text-transform: uppercase;
                    min-height: 14px;
                }
                &-location{
                    width: 163px !important;
                    margin-top: 16px;
                    font-size: 13px;
                    @include RubrikNewRg();
                    color: $deepdark;
                    line-height: 1.54;
                }
            }
        }

    }

    .contactpad {
        width: 163px;

        h4 {
            margin-bottom: 10px;
            font-size: 18px;
            @include RubrikNewSb();
            color: $deepdark;
            text-transform: uppercase;
        }

        span {
            display: block;
            font-size: 13px;
            @include RubrikNewBd();
            color: $deepdark;
        }
        p {
            margin-top: 5px;
            font-size: 13px;
            @include RubrikNewBd();
            color: $deepdark;
        }

        a {
            display: block;
            margin-top: 15px;
            font-size: 13px;
            @include RubrikNewRg();
            color: $deepdark;
            text-decoration: none;

            &:hover {
                color: $red;
                text-decoration: underline;
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .footer {


        .address {
            flex-direction: column;
            &-lane {
                flex-wrap: wrap;
                margin-top: 23px;

                div {
                    &:nth-of-type(1) {
                        width: 100%;
                    }
                }
            }
        }

        .lanebar {
            flex-direction: column;
            padding-right: 0;
            margin-top: 70px;
            .contacts {
                &:nth-of-type(1) {
                    width: 100%;
                }
            }

            &-social {
                order: 2;
                margin-top: 65px;

                a {
                    font-size: 16px;
                }
            }
        }
        &-copy {
            text-align: left;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            span {
                display: none;
            }

            a {
                display: block;
                margin-top: 10px;
            }
        }
    }
}
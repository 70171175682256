.team-page {

    .page_view {
        padding: 105px 0px 86px;
    }

    .team-about{
        padding: 41px 24px 0;


        .page_title {
            font-size: 52px;
        }

        .numbers { 
            flex-wrap: wrap;
            gap: 0;

            & > div {
                flex: 0 0 50%;
            }

            .percent {
                p {
                    @include RubrikNewRg();
                    font-size: 42px;
                    color: #d40101;
                }
            }
            p {
                text-transform: uppercase;
                font-size: 18px;
            }
        }
    }

    .featured-section {
        position: relative;
        justify-content: flex-end;
        padding: 48px 35px 75px;
        @media screen and (max-width: 580px) {
            padding: 48px 23px 75px;
        }
        .line-text {
            position: absolute;
            width: 140px;
            left: 45px;
            top: 120px;
            margin-right: 0;
            font-size: 26px;

            @media screen and (max-width: 580px) {
                left: 25px;
                top: 105px;
            }
        }
        .employer {
            flex-direction: column;
            align-items: flex-end;
        }
        .employer-image-wrapper {
            margin-right: 0;
            max-width: 441px;
            min-width: 441px;
            @media screen and (max-width: 580px) {
                max-width: 365px;
                min-width: 365px;
            }
        }

        .employer-about {
            width: 100%;
            margin-top: 43px;

            &-text {
                margin-bottom: 0;
            }

            h3 {
                @include OpenSansRg();
                font-size: 18px;
                line-height: 1.67;
            }

            h1 {
                margin-top: 8px;
            }

            .more-text {
                margin-top: 16px;

                p {
                    @include OpenSansRg();
                    font-size: 18px;
                    line-height: 1.78;
                }
            }

            .btn-route {
                margin-top: 30px;
            }
        }
    }

    .team-tips {
        padding: 0 26px;
    }

    .team-result {
        padding: 0 26px;
    }

    .team-employees-section {
        .team-employees {
            justify-content: space-evenly;
            gap: 25px;
            margin-bottom: 0;
        }
    }

    
    .btn-route {
        margin-top: 116px;
        font-size: 21px;
        font-weight: 500;
        border-width: 2px;
        padding: 21.5px 52.8px;
    }
 }


 @media screen and (max-width: 480px) {
    .team-page {
        .team-about{
          
          &:after {
            margin-top: 35px;
            margin-bottom: 22px;
          }

          &.line {
            &:after {
              margin-top: 47px;
              margin-bottom: 0;
            }
          }

          .page_title {
              font-size: clamp(36px, 11vw, 52px);
          }
            
          .numbers {
            & > div {
              margin-bottom: 0;

              &:nth-child(n+3) {
                margin-top: 23px;
              }
            }
          }
        }
        
        .featured-section {
            margin-bottom: 23px;
            .employer-image-wrapper {
                max-width: clamp(150px, 64vw, 304px);
                min-width: clamp(150px, 64vw, 304px);
            }
            .employer-about {
                h3 {
                    font-size: clamp(15px, 3.8vw, 18px);
                }
                h1 {
                    font-size: clamp(34px, 11vw, 52px);
                }
                .more-text {
                    p {
                        font-size: clamp(14px, 3.8vw, 18px);
                    }
                }
                .btn-route {
                    font-size: clamp(14px, 5vw, 21px);
                }
            }
        }
        
        .accordion {
            padding: 22px 26px 0;
        }
        .team-tips {
            padding: 0;
            margin-bottom: 0;
            margin-top: 35px;
            row-gap: 21px;

            
            &_wrapper {
                padding: 8px 20.5px;
            }
        }

        .team-result {
            margin-top: 50px;
            .no-matches {
                line-height: 1.25;
            }
        }

        .team-employees-section {
            .team-employees {
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 1fr));
                grid-template-rows: auto;
                gap: 9px;
                .keen-slider__slide {
                    height: fit-content;
                    .keen-slider__slide-img {
                        min-height: 262px;
                        max-height: 262px;
                        @media screen and (max-width: 428px) {
                            min-height: 240px;
                            max-height: 240px;
                        }
                    }
                    .hidden-item__position {
                        display: none;
                    }
                    .hidden-item__name {
                        padding-left: 14px;
                        padding-bottom: 17px;
                        font-size: clamp(14px, 5vw, 21px);
                        max-width: 130px;
                    }

                    .hidden-item {
                        &__img-preview {
                            max-width: 60px;
                            max-height: 60px;
                            &:nth-child(1) {
                                top: -20px;
                                right: -31px;
                            }
                            &:nth-child(2) {
                                max-width: fit-content;
                                left: -40px;
                                bottom: 140px;
                            }
                            &:nth-child(3) {
                                max-width: 75px;
                                max-height: 75px;
                                bottom: -19px;
                                right: 2px;
                                img {
                                    max-width: 75px;
                                    max-height: 75px;
                                }
                            }

                            img {
                                max-width: 60px;
                                max-height: 60px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 428px) {
    .team-page {
        .team-result {
            padding: 0 15px;
        }
        .team-tips {
            gap: 0;
            margin-top: 23px;
            margin-left: -8px;

            &_wrapper {
                padding: 6.5px 10.5px;
                border-radius: 22.5px;
                margin-left: 8px;
                margin-top: 12px;
            }
            &_tip {
                font-size: 12px;
            }
        }
        .team-employees-section {
            .team-employees {
                .keen-slider__slide {
                    .hidden-item {
                        &__img-preview {
                            max-width: 45px;
                            max-height: 45px;
        
                            &:nth-child(1) {
                                top: -15px;
                                right: -20px;
                            }
        
                            &:nth-child(2) {
                                max-width: fit-content;
                                left: -35px;
                                bottom: 120px;
                            }
        
                            &:nth-child(3) {
                                max-width: 65px;
                                max-height: 65px;
                                bottom: -19px;
                                right: 12px;
        
                                img {
                                    max-width: 65px;
                                    max-height: 65px;
                                }
                            }
        
                            img {
                                max-width: 45px;
                                max-height: 45px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 414px) {
    .team-page {
        .featured-section {
            padding: 38px 15px 65px;
            .line-text {
                left: 15px;
                top: 80px;
                width: 128px;
                font-size: 24px;
            }
        }
        .team-result {
            padding: 0 22px;
        }
        .accordion {
            padding: 22px 22px 0;
        }
        .team-employees-section {
            .team-employees {
                .keen-slider__slide {
                    .keen-slider__slide-img {
                        min-height: 100%;
                        max-height: 100%;
                    }
                    .hidden-item__name {
                        padding-left: 14px;
                        padding-bottom: 17px;
                        font-size: 20px;
                        max-width: 115px;
                        overflow: visible;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 375px) {
    .team-page {
        .team-employees-section {
            .team-employees {
                .keen-slider__slide {
                    .hidden-item__name {
                        padding-left: 12px;
                        padding-bottom: 15px;
                        font-size: 18px;
                        max-width: 105px;
                        overflow: visible;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 320px) {
    .team-page {
        .featured-section {
            .line-text {
                left: 15px;
                top: 68px;
                width: 102px;
                font-size: 19px;
            }
        }
        .team-result {
            padding: 0 20px;
        }
        .team-employees-section {
            .team-employees {
                .keen-slider__slide {
                    .hidden-item {
                        &__img-preview {
                            max-width: 37px;
                            max-height: 37px;
                    
                            &:nth-child(1) {
                                top: -12px;
                                right: -19px;
                            }
                    
                            &:nth-child(2) {
                                max-width: fit-content;
                                left: -35px;
                                bottom: 90px;
                            }
                    
                            &:nth-child(3) {
                                max-width: 55px;
                                max-height: 55px;
                                bottom: -19px;
                    
                                img {
                                    max-width: 55px;
                                    max-height: 55px;
                                }
                            }
                    
                            img {
                                max-width: 37px;
                                max-height: 37px;
                            }
                        }

                        &__name {
                            padding-left: 10px;
                            padding-bottom: 17px;
                            font-size: 16px;
                            max-width: 105px;
                            overflow: visible;
                        }
                    }
                }
            }
        }
    }
}
.our-work {

    
    .page_view {
        padding: 105px 29.5px 86px;
    }

    .page_title {
        font-size: 52px;
    }

    &_view {
        padding-top: 41px;
    }
    
    .work-tips {
        gap: 10px;
        margin: 0;
    
    }

    .work-result {
        margin-top: 63px;
        position: relative;
        
        .no-matches {
            position: absolute;
        }

        .btn-route {
            margin-top: 67px;
        }
    }


    .grid-list {
        gap: 61px 30px;
        
        .grid-text {
            margin-top: 8px;

            h3 {
                line-height: 1;
                text-transform: none;
                @include RubrikNewRg();
            }
            p {
                line-height: 1.3;
                font-size: 28px;
                color: #000;
                text-transform: none;
            }
        }
    }

}


@media screen and (max-width: 480px) {
    .our-work {
        .accordion {
            margin-top: 28px;
        }
        .work-tips {
          padding-top: 35px;
        }
        .page_title {
            font-size: clamp(36px, 11vw, 52px);
        }
        .grid-list {
            grid-template-columns: auto;

            .grid-image {
                img {
                    height: clamp(165px, 50vw, 249px);
                }
            }
        }
    }
}

@media screen and (max-width: 428px) {
    .our-work {

        .page_view {
            padding: 105px 15px 86px;
        }

        .grid-list {
            gap: 49px 30px;
            .grid-text {
                h3 {
                    font-size: 16px;
                }
                p {
                    font-size: 26px;
                }
            }
        }

        .work-tips {
            gap: 8px;

            &_wrapper {
                padding: 6.5px 10.5px;
                border-radius: 22.5px;
            }
            &_tip {
                font-size: 12px;
            }
        }
    }
}

.clients {
    padding: 95px 50px 80px;

    @media screen and (max-width: 480px) {
        padding: 69px 32px 67px;
    }

    &-tiles {
        margin-top: 53px;
        grid-template-columns: repeat(auto-fit, minmax(85px, 1fr));
        grid-gap: 65px 45px;

        @media screen and (max-width: 480px) {
            grid-gap: 30px 30px;
        }

        img {
            width: 80px;

            
            @media screen and (max-width: 480px) {
                width: 78px;
            }
        }

    }
}
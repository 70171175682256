.clients {
    position: relative;
    padding: 115px 65px 95px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    //margin-top: 300px;
    //margin-bottom: 300px;
    &-title {
        font-size: 21px;
        @include RubrikNewSb();
        color: #fff;
        text-transform: uppercase;

        &::after {
            content: "";
            display: block;
            width: 46px;
            height: 2px;
            margin-top: 3px;
            background-color: #d40101;
        }
    }

    &-tiles {
        margin-top: 90px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
        grid-gap: 95px 15px;
        place-items: center;
        .tile-item {
            //display: grid;
            //place-items: center;
            opacity: 0.7;
            p {
                color: #fff;
            }
            img {
                max-width: 150px;
            }
        }
    }
}

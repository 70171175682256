// global variables
@import 'vars';

// global resets
@import 'resets';

// fonts
@import 'fonts';

/*  Use "desktop" for default styling, then override with tablet & mobile */
@import "desktop";

@media screen and (max-width: $tablet-bp) {
    @import "tablet";
}

@media screen and (max-width: $mobile-bp) {
    @import "mobile";
}


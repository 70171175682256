.privacy-section {
    
}
.privacy-view {
    padding: 281px 20% 95px;

    &_title {
        @include RubrikNewSb();
        font-size: 52px;
        text-align: center;
        color: #000;
    }
    &_date {
        margin-top: 80px;
        @include RubrikNewSb();
        font-size: 21px;
        line-height: 1.71;
        text-align: center;
        color: #000;
    }
    &_prompt {
        margin-top: 35px;
        @include OpenSansRg(); 
        font-size: 16px;
        line-height: 1.88;
        color: #000;
        text-transform: uppercase;
    }
    &_desc {
        margin-top: 22px;
        @include OpenSansRg(); 
        font-size: 16px;
        line-height: 1.88;
        color: #000;
        b {
            font-weight: 600;
        }
    }

}

.terms {
    h6 {
        margin-top: 40px;
        @include RubrikNewSb();
        font-size: 36px;
        line-height: 1.25;
        color: #000;
        word-break: break-word;
        letter-spacing: -.5px;
    }
    p {
        margin-top: 20px;
        @include OpenSansRg(); 
        font-size: 16px;
        line-height: 1.88;
        color: #000;

        u {
            display: block;
            width: 290px;
            margin-top: 15px;
            margin-left: 40px;
            @include OpenSansRg(); 
            font-size: 16px;
            line-height: 1.4;
            color: #000;
            text-decoration: none;

            &:nth-of-type(2) {
                margin-top: 5px;
            }
        }
        
        b {
            display: block;
            font-weight: 600;
        }

        i {
            margin-top: 40px;
            @include OpenSansSb();
            font-size: 24px;
            font-style: normal;
            line-height: 1.5;
            color: #000;
        }
    }
    
    ul {
        margin-top: 15px;
        margin-left: 40px;
        li {
            @include OpenSansRg(); 
            font-size: 16px;
            line-height: 1.88;
            color: #000;
            list-style-type: disc;
            p {
                margin-top: 0;
            }
        }
    }

}